import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerForm from '../../components/CustomerForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import {
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
const UpdateBankAccount = (props) => {
  const { id, toggleModalOpen, modalOpen, className, contractId } = props;
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const query = new URLSearchParams(useLocation().search);

  const [customer, setCustomer] = useState({
    name: '',
    birth_date: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    mobile: '',
    email: ''
  });

  const [ banks, setBanks ] = useState([])

  async function getBanks() {
    showLoader()

    try {
      const response = await api.get(
        `banks?q[active_eq]=true&q[has_credit_card_eq]=true`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.code} - ${value.name}`,
          label: `${value.code} - ${value.name}`
        }
      })

      setBanks(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os bancos.');
    }
  }

  useEffect(() => {
    async function getCustomer() {
      showLoader();
      try {
        const { data } = await api.get(`customers/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(data)
        setCustomer({
          ...customer,
          entity_id: data.entity.id,
          id: data.id,
          mobile: data.mobile,
          rg: data.rg,
          email: data.email,
          birth_date: data.birth_date,
          name: data.entity.name,
          cpf_cnpj: data.entity.cpf_cnpj,
          mother_name: data.mother_name,
          father_name: data.father_name,
          address_id: data.address ? data.address.id : null,
          zip_code: data.address ? data.address.zip_code : null,
          street: data.address ? data.address.street : null,
          number: data.address ? data.address.number : null,
          district: data.address ? data.address.district : null,
          city: data.address ? data.address.city : null,
          state: data.address ? data.address.state : null,
          complement: data.address ? data.address.complement : null,
          bank_account_id: data.bank_account ? data.bank_account.id : null,
          bank_id: data.bank_account ? data.bank_account.id : null,
          bank_name: data.bank_account ? `${data.bank_account.number_bank} - ${data.bank_account.name_bank}` : null,
          agency_account: data.bank_account ? data.bank_account.agency_account : null,
          agency_digit: data.bank_account ? data.bank_account.agency_digit : null,
          account_digit: data.bank_account ? data.bank_account.account_digit : null,
          number_account: data.bank_account ? data.bank_account.number_account : null,
          account_kind: data.bank_account ? data.bank_account.kind : null,
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    if(id){
      getCustomer();
      getBanks()
    }
  }, [id]);

  async function onSubmit(data) {
    showLoader();
    console.log(customer)

    let bank_account_attributes = {
      number_bank: customer.bank_name.split('-')[0],
      name_bank: customer.bank_name.split('-')[1],
      agency_account: customer.agency_account,
      agency_digit: customer.agency_digit,
      number_account: customer.number_account,
      account_digit: customer.account_digit,
      kind: customer.account_kind,
    }


    if(customer.bank_account_id){
      bank_account_attributes = {...bank_account_attributes, id: customer.bank_account_id}
    }

    data = {
      customer: {
        entity_attributes: {
          id: customer.entity_id,
          bank_account_attributes
        }
      }
    }

    console.log(data)

    try {
      await api.put(`contracts/${contractId}/update_bank_account_money_plus`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Cliente atualizado com sucesso');
      toggleModalOpen()

    } catch (error) {
      hideLoader();

      toastError(error.response.data.error);
    }
  }

  function handleOnChangeBank(e){
    setCustomer({ ...customer, 'bank_name': e.value })
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    if (e.target.name == "agency_digit") {
      setCustomer ({ ...customer, ["agency_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "account_digit") {
        setCustomer ({ ...customer, ["account_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "number_account") {
        setCustomer ({ ...customer, ["number_account"] : parseAccount(e.target.value) });
      } else if (e.target.name == "cpf_cnpj") {
        if ( e.target.value.length < 15 ) {
          let cpf_cnpj = e.target.value
          if(cpf_cnpj.length >= 12) {
            cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
          } else {
            cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
          }
          setCustomer ({ ...customer,  [e.target.name]: cpf_cnpj});
        }
      } else if (e.target.name == "mobile") {
          if ( e.target.value.length < 16 ) {
            let mobile = e.target.value

            if(mobile.length > 10) {
              mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
            } else {
              mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
            }
            setCustomer ({ ...customer,  [e.target.name]: mobile});
          }
      } else {
        setCustomer({ ...customer, [e.target.name]: e.target.value });
      }
    }

  function dataAddress({ street, district, city, state }) {
    setCustomer({
      ...customer,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  return (
    <Modal
      size='lg'
      isOpen={modalOpen}
      toggle={toggleModalOpen}
      className={className}
      backdrop="static"
    >
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <CustomerForm
                banks={banks}
                onlyBank
                customer={customer}
                title="Atualizar cliente"
                titleButton="Atualizar cliente"
                onSubmit={onSubmit}
                handleOnChange={handleOnChange}
                handleOnChangeBank={handleOnChangeBank}
                dataAddress={dataAddress}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
              />
            </Col>
          </Row>

        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggleModalOpen}>
          Fechar
        </Button>
        <Button color="success" onClick={() => onSubmit({})}>
          Alterar
        </Button>
      </ModalFooter>
    </Modal>

  );
};

export default UpdateBankAccount;
