/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AppProvider from './context/index';
import PrivateRoute from './routes/PrivateRoute';
import Register from './views/examples/Register';
import Login from './views/examples/Login';

import Index from './views/Index';
import ProfileCommission from './pages/ProfileCommission';
import FgtsProfileCommission from './pages/FgtsProfileCommission';
import Profile from './views/examples/Profile';
import CreateContract from './pages/CreateContract';
import UpdateContract from './pages/UpdateContract';
import Loading from './components/Loading';
import Users from './pages/Users';
import UserLogs from './pages/Users/logs';
import CreateUser from './pages/CreateUser';
import UpdateUser from './pages/UpdateUser';
import Payment from './pages/Payment';
import FinishPayment from './pages/FinishPayment';
import Contracts from './pages/Contracts';
import ContractsFgts from './pages/Contracts/fgts';
import ContractsFgtsUy3 from './pages/Contracts/uy3';
import ContractLogs from './pages/Contracts/logs';
import Customers from './pages/Customers';
import UpdateCustomer from './pages/UpdateCustomer';
import ListContracts from './pages/ListContracts/ListContracts';
import CreateCustomer from './pages/CreateCustomer';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import FormCancel from './pages/FormCancel';
import Corbans from './pages/Corban';
import CreateCorban from './pages/CreateCorban';
import UpdateCorban from './pages/UpdateCorban';
import CorbanLogs from './pages/Corban/logs';
import Subestablishments from './pages/Subestablishment';
import SubLogs from './pages/Subestablishment/logs';
import CreateSubestablishments from './pages/CreateSubestablishment';
import UpdateSubestablishment from './pages/UpdateSubestablishment';
import Stores from './pages/Store';
import Signer from './pages/Signer';
import CreateSigner from './pages/Signer/create';
import UpdateSigner from './pages/Signer/update';
import StoresPending from './pages/StorePending';
import StoreLogs from './pages/Store/logs';
import StoreDetail from './pages/Store/detail';
import SellerDetail from './pages/Seller/detail';
import Ranking from './pages/Ranking';
import RankingFgts from './pages/RankingFgts';
import CreateStores from './pages/CreateStore';
import UpdateStore from './pages/UpdateStore';
import Sellers from './pages/Seller';
import SellerLogs from './pages/Seller/logs';
import CreateSellers from './pages/CreateSeller';
import UpdateSeller from './pages/UpdateSeller';
import UpdatePassword from './pages/UpdatePassword';
import CustomerServices from './pages/CustomerServices';
import CustomerServicesFgts from './pages/CustomerServices/fgts';
import CreateCustomerService from './pages/CreateCustomerService';
import CreateCustomerServiceFgts from './pages/CreateCustomerService/fgts';
import CreateCustomerServiceFgtsUy3 from './pages/CreateCustomerService/uy3';
import SimulatorSetting from './pages/SimulatorSetting';
import CreateSimulatorSetting from './pages/CreateSimulatorSetting';
import BasicInterests from './pages/BasicInterest';
import CreateBasicInterest from './pages/CreateBasicInterest';
import UpdateBasicInterest from './pages/UpdateBasicInterest';
import FgtsBasicInterests from './pages/FgtsBasicInterest';
import FgtsCreateBasicInterest from './pages/FgtsCreateBasicInterest';
import UploadContracts from './pages/Contracts/upload';
import UploadContractFgts from './pages/Contracts/upload_contract';
import UploadCustomers from './pages/Customers/upload';
import DetailCustomerService from './pages/CustomerServices/detail';
import DetailContract from './pages/Contracts/detail';
import ContractsNsu from './pages/Contracts/nsu';
import StoreCommission from './pages/StoreCommission';
import Commission from 'pages/Store/commission';
import GatewaysSetting from 'pages/GatewaySetting';
import CreateGatewaySetting from 'pages/GatewaySetting/create';
import ContractTransfer from 'pages/Contracts/transfer';
import Authentication from 'pages/Authentication';
import Report from 'pages/Report';
import TagManager from 'react-gtm-module';
import CreateReferrer from 'pages/CreateReferrer/intex';
import Referrers from 'pages/Referrers';
import UpdateReferrer from 'pages/UpdateReferrer';
import ImportContract from 'pages/ImportContract';
import CreateImportContract from 'pages/ImportContract/create';
import Establishment from 'pages/Establishment';
import CreateEstablishment from 'pages/CreateEstablishment';
import UpdateEstablishment from 'pages/UpdateEstablishment';
import UpdateCommission from 'pages/UpdateCommission';
import UpdateSecure from 'pages/UpdateSecure';
import CdcProfileCommission from 'pages/CdcProfileCommission';
import CorbanTransfer from 'pages/Transfers';
import Banks from 'pages/Banks';

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-NCNR7GN' });
  }, []);

  return (
    <AppProvider>
      <Router>
        <Switch>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <PrivateRoute
            exact
            path="/login"
            component={Login}
            isPrivate={false}
          />

          <PrivateRoute
            exact
            path="/admin/customers/:id/upload"
            component={UploadCustomers}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/contracts/:id/upload_fgts"
            component={UploadContractFgts}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/fgts-customer-services"
            component={CustomerServicesFgts}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-customer-services/create"
            component={CreateCustomerServiceFgtsUy3}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-customer-services/create-uy3"
            component={CreateCustomerServiceFgtsUy3}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-basic-interests"
            component={FgtsBasicInterests}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-basic-interests/create"
            component={FgtsCreateBasicInterest}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-contracts"
            component={ContractsFgtsUy3}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/fgts-contracts-uy3"
            component={ContractsFgtsUy3}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/forgot"
            component={ForgotPassword}
            isPrivate={false}
          />

          <PrivateRoute
            exact
            path="/reset"
            component={ResetPassword}
            isPrivate={false}
          />

          <PrivateRoute
            exact
            path="/register"
            component={Register}
            isPrivate={false}
          />

          <Route exact path="/autenticacao/:id" component={Authentication} />
          <Route exact path="/payment/success" component={FinishPayment} />
          <Route exact path="/payment/:id" component={Payment} />

          <PrivateRoute path="/admin/index" component={Index} isPrivate />
          <PrivateRoute path="/admin/profile" component={Profile} isPrivate />
          <PrivateRoute
            path="/admin/commission"
            component={ProfileCommission}
            isPrivate
          />
          <PrivateRoute
            path="/admin/fgts_commission"
            component={FgtsProfileCommission}
            isPrivate
          />
          <PrivateRoute
            path="/admin/cdc_commission"
            component={CdcProfileCommission}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/gateways"
            component={GatewaysSetting}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/gateways/create"
            component={CreateGatewaySetting}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/import_contracts"
            component={ImportContract}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/import_contracts/create"
            component={CreateImportContract}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/customers"
            component={Customers}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/corbans"
            component={Corbans}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/corbans/create"
            component={CreateCorban}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/corbans/:id"
            component={UpdateCorban}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/corbans/:id/logs"
            component={CorbanLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/subestablishments"
            component={Subestablishments}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/subestablishments/create"
            component={CreateSubestablishments}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/subestablishments/:id"
            component={UpdateSubestablishment}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/subestablishments/:id/logs"
            component={SubLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/signers"
            component={Signer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/signers/create"
            component={CreateSigner}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/signers/:id"
            component={UpdateSigner}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/:id/commissions"
            component={Commission}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores"
            component={Stores}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/establishments"
            component={Establishment}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/commissions"
            component={StoreCommission}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/pendings"
            component={StoresPending}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/create"
            component={CreateStores}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/establishment/create"
            component={CreateEstablishment}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/establishments/:id"
            component={UpdateEstablishment}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/:id"
            component={UpdateStore}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/stores/:id/logs"
            component={StoreLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/sellers"
            component={Sellers}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/password/users"
            component={UpdatePassword}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/sellers/create"
            component={CreateSellers}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/sellers/:id"
            component={UpdateSeller}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/sellers/:id/logs"
            component={SellerLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts"
            component={Contracts}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/:id/transfers"
            component={ContractTransfer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/transfers"
            component={CorbanTransfer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/bancos"
            component={Banks}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/:id/nsu"
            component={ContractsNsu}
            isPrivate
          />

          <PrivateRoute
            path="/admin/contracts/cancel/:id"
            component={FormCancel}
            isPrivate
          />

          <PrivateRoute
            path="/admin/contracts/:id/detail"
            component={DetailContract}
            isPrivate
          />

          <PrivateRoute
            path="/admin/customer-services/:id/detail"
            component={DetailCustomerService}
            isPrivate
          />

          <PrivateRoute
            path="/admin/customer-services/:id/detail"
            component={DetailCustomerService}
            isPrivate
          />

          <PrivateRoute
            path="/admin/stores/:id/detail"
            component={StoreDetail}
            isPrivate
          />

          <PrivateRoute
            path="/admin/sellers/:id/detail"
            component={SellerDetail}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/customers/create"
            component={CreateCustomer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/customer/:id"
            component={ListContracts}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/:id/logs"
            component={ContractLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/create"
            component={CreateContract}
            isPrivate
          />

          <PrivateRoute exact path="/admin/users" component={Users} isPrivate />

          <PrivateRoute
            exact
            path="/admin/users/create"
            component={CreateUser}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/users/:id/editar"
            component={UpdateUser}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/users/:id/logs"
            component={UserLogs}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/:id"
            component={UpdateContract}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/contracts/:id/upload"
            component={UploadContracts}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/customers/:id"
            component={UpdateCustomer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/customer-services"
            component={CustomerServices}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/customer-services/create"
            component={CreateCustomerService}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/basic-interests"
            component={BasicInterests}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/basic-interests/create"
            component={CreateBasicInterest}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/simulator-settings"
            component={SimulatorSetting}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/simulator-settings/create"
            component={CreateSimulatorSetting}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/basic-interests/:id"
            component={UpdateBasicInterest}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/rankings"
            component={Ranking}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/rankings_fgts"
            component={RankingFgts}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/report"
            component={Report}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/referrers"
            component={Referrers}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/referrers/create"
            component={CreateReferrer}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/referrers/:id"
            component={UpdateReferrer}
            isPrivate
          />
          <PrivateRoute
            exact
            path="/admin/update_commission"
            component={UpdateCommission}
            isPrivate
          />

          <PrivateRoute
            exact
            path="/admin/update_secure"
            component={UpdateSecure}
            isPrivate
          />

          <Redirect from="/" to="/login" />
        </Switch>
      </Router>

      <Loading />
    </AppProvider>
  );
};

export default App;
