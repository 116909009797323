import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Table,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  CustomInput,
} from 'reactstrap';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import moment from 'moment';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { VisibilityByUserRoles } from '../../util/visibility';
import { useLocation } from 'react-router-dom';
import TableSimulationFgts from 'components/SimulationFgts/table_uy3';

let sendTime = null;

const CreateCustomerServiceFgtsUy3 = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const { register, handleSubmit, errors, setError } = useForm();
  const [creditLimitWithoutIof, setCreditLimitWithoutIof] = useState(null);
  const [fgtsCommissionId, setFgtsCommissionId] = useState(null);
  const [diffIof, setDiffIof] = useState(null);
  const [installmentsSize, setInstallmentsSize] = useState(10);
  const [totalInstallmentsSize, setTotalInstallmentsSize] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [valueFree, setValueFree] = useState(null);
  const [fullValue, setFullValue] = useState(false);
  const [minRate, setMinRate] = useState(null);
  const [disabledButtonAttendance, setDisabledButtonAttendance] =
    useState(false);
  const [maxRate, setMaxRate] = useState(null);
  const [maxValueCdc, setMaxValueCdc] = useState(0);
  const [rateStep, setRateStep] = useState(null);
  const [kindSimulation, setKindSimulation] = useState(null);
  const [minCommission, setMinCommission] = useState(null);
  const [maxCommission, setMaxCommission] = useState(null);
  const [stepCommission, setStepCommission] = useState(null);
  const [fgtsBasicInterests, setFgtsBasicInterests] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [deadlineDisabled, setDeadlineDisabled] = useState(true);
  const [simulationId, setSimulationId] = useState(null);
  const [webhookId, setWebhookId] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [tacs, setTacs] = useState([]);
  const [tacValue, setTacValue] = useState();
  const [parcels, setParcels] = useState([]);
  const [simulationData, setSimulationData] = useState([]);
  const [simulationDataFgts, setSimulationDataFgts] = useState(null);
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [deadline, setDeadline] = React.useState([10]);
  const [rate, setRate] = React.useState(null);
  const [commissionSeller, setCommissionSeller] = React.useState(0);
  const [hasSmart, setHasSmart] = React.useState(false);
  const [commissionSellerPercent, setCommissionSellerPercent] =
    React.useState(0);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(29000);
  const [maxValue1, setMaxValue1] = React.useState(23000);
  const [maxValue2, setMaxValue2] = React.useState(19000);
  const [liquidValue, setLiquidValue] = React.useState(0);
  const [bonusSeller, setBonusSeller] = React.useState(null);
  const [commission, setCommission] = React.useState(null);
  const [tacPercent, setTacPercent] = React.useState(null);
  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState(null);

  const [valuesPortion, setValuesPortion] = React.useState([
    3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [sellers, setSellers] = React.useState([]);
  const [seller, setSeller] = React.useState(null);
  const [selectedDataFgts, setSelectedDataFgts] = React.useState(null);
  const [fgtsCommissions, setFgtsCommissions] = React.useState([]);
  const [attendance, setAttendance] = useState({
    name: '',
    email: '',
    birth_date: '',
    released_amount: '',
    cpf: '',
    phone: '',
    deadline: null,
    value_free: null,
    value_client: null,
    value_installment: null,
    card_limit: null,
    iof_value: null,
    date_credit: moment().format('YYYY-MM-DD'),
    date_first_installment: moment().add(30, 'days').format('YYYY-MM-DD'),
    phone_store: '',
    phone_seller: '',
  });
  const [storeName, setStoreName] = useState('');
  const [hasCampaign, setHasCampaign] = useState(false);

  const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const simulationResultRef = React.useRef(null);

  async function getSystemSettings() {
    showLoader();

    try {
      const response = await api.get(`system_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(response.data);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  }

  const getSimulatorSetting = async () => {
    try {
      let { data } = await api.get(
        `simulator_settings/fgts?${seller ? `seller_id=${seller}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (data) {
        setHasCampaign(data.has_campaign);
        console.log(data);

        // setFgtsBasicInterests(data)
        setMinRate(parseFloat(data.min_rate));
        setMaxRate(parseFloat(data.max_rate));
        setRate([parseFloat(data.max_rate)]);
        // setMinCommission(parseFloat(data.min_commission))
        // setMaxCommission(parseFloat(data.max_commission))
        // setCommission([parseFloat(data.min_commission)])
        // const commissionStep = parseFloat(data.max_commission) - parseFloat(data.min_commission)
        setRateStep(parseFloat(data.max_rate) - parseFloat(data.min_rate));

        // setStepCommission(commissionStep/rateStep)
        // setKindSelection('value_free')
      }
    } catch (e) {
      console.log(e);
      console.log('Ops, houve um erro na busca simulator config');
    }
  };

  useEffect(() => {
    if (user && user.roles && user.roles[0].name == 'master_store') {
      setStore(user.store_id);
      getSellers(user.store_id);
    }

    if (user && user.roles && user.roles[0].name == 'user_seller') {
      getSimulatorSetting();
      getFgtsEnabled();
    }

    if (user && user.roles && user.roles[0].name == 'master') {
      setDeadlineDisabled(false);
    }
  }, [user]);

  // useEffect(() => {
  //   getWebhook(query.get("webhook"))
  // }, [])

  useEffect(() => {
    if (seller) {
      getSimulatorSetting();
      getSystemSettings();
      getFgtsEnabled();
    }
  }, [seller]);

  async function getStore() {
    try {
      const { data } = await api.get('stores/searchs', {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (data) {
        setStores(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os Lojas');
    }
  }

  const sortByText = (a, b, order = 'ASC') => {
    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (order === 'ASC') {
      return diff;
    }

    return -1 * diff;
  };

  async function getSellers(store_id) {
    try {
      const { data } = await api.get(
        `sellers/searchs?q[store_id_eq]=${store_id}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      if (data) {
        setSellers(data.sort((a, b) => sortByText(a, b, 'ASC')));
      }
    } catch (error) {
      console.log('Algo deu errado ao buscar os parceiros');
    }
  }

  function parseMoney(value) {
    if (value) {
      return value.toFixed(2).toString().replace('.', ',');
    } else {
      return 0;
    }
  }

  function getPercentValue(value, total) {
    if (value == 0 || value == null) {
      return 0;
    }
    if (total == 0 || total == null) {
      return 0;
    }
    return parseMaskCurrency(((100 * value) / parseCurrency(total)).toFixed(2));
  }

  async function getCommissionSeller(value, productId) {
    clearTimeout(sendTime);
    setLoading(true);
    sendTime = setTimeout(async function () {
      try {
        let { data } = await api.post(
          `simulations/commissions_fgts_uy3?${
            seller ? `seller_id=${seller}` : ''
          }`,
          { value, product_id: productId },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        console.log(data);

        if (data) {
          setCommissionSeller(data.commission_seller_value);
          setCommissionSellerPercent(data.commission_seller_percent);
          if (
            data.commission_seller_trigger_value &&
            data.commission_seller_trigger_value != 0
          ) {
            setBonusSeller(data.commission_seller_trigger_value);
          } else {
            setBonusSeller(null);
          }
        }
      } catch (error) {
        console.log(error);
        let message = '';

        if (error.response.data.errors) {
          message = error.response.data.errors;
          message.forEach((e) => {
            toastError(e.msg);
          });
        } else if (error.response.data) {
          message = error.response.data.message;
        } else {
          message =
            'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
        }

        toastError(message);
      }
      setLoading(false);
    }, 1000);
  }

  async function getBalance(
    valueFree = null,
    rate = 0.0179999999,
    changeInstallment = true,
    tac = [30],
    valueClient = null,
    installments = installmentsSize
  ) {
    console.log(valueFree);
    console.log(valueClient);

    if (query.get('isCdc')) {
      valueFree = valueFree / 0.9;
      valueFree += valueClient;
      valueFree = parseFloat(valueFree.toFixed(2));
      if (valueFree > maxValueCdc) {
        return toastError(
          `O valor liberado deve ser menor ou igual a ${maxValueCdc}`
        );
      }
    }

    if (attendance.cpf) {
      showLoader();
      console.log('PADD');
      console.log(tac);
      try {
        if (valueFree) {
          setDisabledButtonAttendance(true);
          setFullValue(true);
        } else {
          setFullValue(false);
        }

        let { data } = await api.post(
          `simulations/balance_fgts_uy3?${seller ? `seller_id=${seller}` : ''}`,
          {
            cpf: attendance.cpf,
            rate: 0.0179999999,
            installments: installments,
            value_free: valueFree,
            birth_date: '1980-05-20',
            tac: tac[0],
          },
          {
            headers: {
              Authorization: `Bearer ${token()}`,
              Application: `nsaque-front-end`,
            },
          }
        );

        console.log(data);
        let arrayValues = data[0].response.paymentScheduleItems
          .map((item) => item.payment / 100)
          .sort();

        if (!maxValueCdc) {
          setMaxValueCdc(arrayValues[arrayValues.length - 1]);
        }
        setDeadline([installments]);
        setInstallmentsSize(installments);
        setTotalInstallmentsSize([installments]);
        setTacs([]);

        // Setting soft product to correct position
        const softProduct =
          data && data.find((item) => item.table_name === 'Soft');
        const smartProduct =
          data && data.find((item) => item.table_name === 'Smart');

        let nextSimulationDataFgts = data && [
          softProduct,
          ...data.slice(0, -1),
        ];

        if (smartProduct) {
          nextSimulationDataFgts = data && [
            smartProduct,
            softProduct,
            ...data.slice(0, -1),
          ];
        }

        setSimulationDataFgts(nextSimulationDataFgts);

        setLiquidValue(data[0].response.paymentScheduleItems[1].payment / 100);

        setSelectedDataFgts(nextSimulationDataFgts[0]);
        console.log(nextSimulationDataFgts[0].productId);
        getCommissionSeller(
          nextSimulationDataFgts[0].response.liquidValue / 100,
          nextSimulationDataFgts[0].product_id
        );
        setTacValue(
          nextSimulationDataFgts[0].response.paymentScheduleItems[1]
            .financeTax / 100
        );
        if (!query.get('isCdc')) {
          setAttendance({ ...attendance, value_free: null });
        }
        hideLoader();
      } catch (error) {
        hideLoader();
        setDisabledButtonAttendance(false);
        console.log('DEU ERRO');
        console.log(error);
        let message = '';

        if (error.response?.data?.errors) {
          message = error.response.data.errors;
          message.forEach((e) => {
            toastError(e.msg);
          });
        } else if (error?.response?.data?.data) {
          message = error.response?.data?.data?.message;
        } else if (error?.response?.data) {
          message = error.response.data.message;
        } else {
          message = 'Ops, tivemos um erro ao efetuar simulação!';
        }

        toastError(message);
        window.scrollTo(0, 0);
      }
    }
  }

  function handleOnChange(e) {
    if (e.target.name === 'cpf') {
      if (e.target.value === '' || e.target.value === '___.___.___-__') {
        setError('cpf', 'CPF obrigatório');
      } else if (!isValidCpf(e.target.value)) {
        setError('cpf', 'CPF inválido');
      } else {
        setError('cpf', '');
      }
    }
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  }

  function setKindSelection(value) {
    setAttendance({
      ...attendance,
      value_free: 0,
      value_installment: 0,
      card_limit: 0,
    });
    setCommissionSeller(0);
    setBonusSeller(null);
    setKindSimulation(value);
  }

  function registerCustomer(attendanceId, isCdc = false) {
    if (customerId) {
      swal({
        title: 'Atendimento',
        text: 'Deseja finalizar o cadastro do cliente?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/${customerId}?customerServiceId=${attendanceId}&kind=fgts${
              isCdc ? '&isCdc=true' : ''
            }`
          );
          // props.history.push(`/admin/contracts/create?customerServiceId=${attendanceId}`);
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    } else {
      swal({
        title: 'Atendimento',
        text: 'Deseja finalizar o cadastro do cliente?',
        icon: 'success',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          props.history.push(
            `/admin/customers/create?customerServiceId=${attendanceId}&kind=fgts${
              isCdc ? '&isCdc=true' : ''
            }`
          );
        } else {
          props.history.push('/admin/customer-services');
        }
      });
    }
  }

  // function handleChangeCommission(values) {
  //   setCommission(values)
  // }

  function isValidCpf(cpf) {
    // Remover a máscara, mantendo apenas os dígitos
    cpf = cpf.replace(/[^\d]/g, '');

    // Verificar se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verificar se todos os dígitos são iguais (caso contrário, não é válido)
    if (/^(\d)\1{10}$/.test(cpf)) {
      return false;
    }

    // Calcular o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = sum % 11;
    let digit1 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digit1) {
      return false;
    }

    // Calcular o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = sum % 11;
    let digit2 = remainder < 2 ? 0 : 11 - remainder;

    // Verificar o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digit2) {
      return false;
    }

    return true; // O CPF é válido
  }

  async function handleOnBlurCpf(event) {
    const { value } = event.target;

    if (value.length > 0) {
      const cpf = value.replace(/[^0-9]+/g, '');

      try {
        let { data } = await api.get(
          `contracts/get_customer_by_cpf?q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        if (data.length > 0) {
          data = data[0];
          console.log(data);
        }

        setCustomerId(data.id);

        setAttendance({
          ...attendance,
          name: data.name,
          phone: data.mobile,
          email: data.email,
        });
      } catch (e) {
        console.log(e);
        console.log('Ops, houve um erro na busca pelo CPF');
      }
    }
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  async function handleCheckBalance(cpf) {
    try {
      let { data } = await api.post(
        `simulations/check_cpf_balance_fgts`,
        {
          cpf: cpf,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
            Application: `nsaque-front-end`,
          },
        }
      );

      if (!data.data.cpf) {
        setAttendance({
          ...attendance,
          value_free: null,
        });
        setDisabledButtonAttendance(false);
        getBalance();
      }
    } catch {
      swal({
        title: 'Atendimento',
        text: 'O cliente foi consultado e não tinha autorizado a instituição financeira. O cliente já autorizou?',
        icon: 'warning',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then(async (next) => {
        if (next) {
          setAttendance({
            ...attendance,
            value_free: null,
          });
          setDisabledButtonAttendance(false);
          getBalance();
        } else {
          console.log('Não');
        }
      });
    }
  }

  async function onSubmit(event) {
    localStorage.setItem('fgts_store_name', event.store);
    // if (simulationDataFgts.liquidValue/100 > maxValue) {
    //   hideLoader();

    //   toastError(
    //     `O valor máximo liberado é de ${maxValue.toLocaleString('pt-br', {
    //       style: 'currency',
    //       currency: 'BRL',
    //     })}`
    //   );

    //   return;
    // }

    if (simulationDataFgts.liquidValue / 100 < minValue) {
      hideLoader();

      toastError(
        `O valor minimo liberado é de ${minValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}`
      );

      return;
    }

    let installments = selectedDataFgts.response.paymentScheduleItems
      .filter((item) => item.payment == 0)
      .map((item) => {
        return {
          total_amount: item.payment / 100,
          // release_amount: item.ValorParcela - item.finTax/100 - item.tc/100,
          release_amount: item.payment / 100,
          due_date: item.dueDate,
        };
      });

    showLoader();
    try {
      let data = {
        simulation: {
          name: event.name,
          interest_rate: parseFloat(
            selectedDataFgts.response.monthlyInterest * 100
          ).toFixed(2),
          email: event.email,
          cpf_cnpj: event.cpf,
          phone: event.phone,
          phone_store: event.phone_store,
          phone_seller: event.phone_seller,
          full_value: fullValue,
          parcels: JSON.stringify(installments),
          simulation_fgts: JSON.stringify(simulationDataFgts),
          simulation_json: JSON.stringify(selectedDataFgts),
          has_secure: false,
          liquid_value: selectedDataFgts.response.liquidValue / 100,
          released_amount: selectedDataFgts.response.liquidValue / 100,
          value_client: parseCurrency(
            selectedDataFgts.response.liquidValue / 100
          ),
          value_establishment: parseCurrency(
            selectedDataFgts.response.liquidValue / 100
          ),
          // card_limit: parseCurrency(parcels[`${deadline[0] - 1}`].ValorParcela),
          card_limit: selectedDataFgts.response.paymentScheduleItems
            .filter((item) => item.payment != 0)
            .map((item) => item.payment / 100)
            .reduce(function (a, b) {
              return a + b;
            }),
          value_installment: parseCurrency(installments[0].total_amount),
          installments: installmentsSize,
          birth_date: '1980-05-20',
        },
        kind: query.get('isCdc') ? 'cdc' : 'fgts',
      };

      if (customerId) {
        data = {
          simulation: {
            ...data.simulation,
            customer_id: customerId,
            status: 1,
          },
          kind: query.get('isCdc') ? 'cdc' : 'fgts',
        };
      }

      if (seller) {
        data = { ...data, seller_id: seller };
      }

      const response = await api.post(
        'simulations/create_fgts_uy3',
        JSON.stringify(data),
        {
          headers: {
            Application: `nsaque-front-end`,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      console.log(data);
      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');
      console.log(response.data);
      registerCustomer(response.data.simulation.id, query.get('isCdc'));
    } catch (error) {
      hideLoader();
      console.log(error);

      let message = '';

      if (error.response.data.errors) {
        message = error.response.data.errors;
        message.forEach((e) => {
          toastError(e.msg);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro na atualização dos dados, por favor tente novamente!';
      }

      toastError(message);
      window.scrollTo(0, 0);
    }
  }

  function handleChangeDeadline(values) {
    setDeadline(values);
    setInstallmentsSize(values);
    console.log(values);
    clearTimeout(sendTime);
    // sendTime = setTimeout(async function () {
    //   getBalance(
    //     null,
    //     0.0179999999,
    //     false,
    //     tacs,
    //     parseCurrency(attendance.value_client),
    //     values
    //   )
    // }, 1000);
  }

  async function handleOnChangeStore(e) {
    setStoreName(e.target.value);
    if (e.target.value.length > 4) {
      let url_stores = `/stores/searchs?q[entity_name_cont]=${e.target.value}`;
      if (query.get('isCdc')) {
        url_stores += `&q[code_qista_not_null]=1`;
      }
      const response = await api.get(url_stores, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const values = response.data.map((value, index) => {
        return {
          value: `${value.id} - ${value.name}`,
          label: `${value.id} - ${value.name}`,
          mobile: value.mobile,
        };
      });
      setStores(values);
    } else if (e.target.value === '') {
      setStores([]);
    }
  }

  async function getFgtsEnabled() {
    const response = await api.get(
      `/simulations/get_fgts_enabled?${seller ? `seller_id=${seller}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    setFgtsCommissions(response.data);

    console.log(response);
  }

  useEffect(() => {
    const sellerSelected = sellers.filter((item) => item.id === Number(seller));
    if (sellerSelected.length > 0) {
      setAttendance((prevState) => ({
        ...prevState,
        phone_seller: sellerSelected[0].mobile,
      }));
    }
  }, [seller]);

  useEffect(() => {
    if ((simulationDataFgts && selectedDataFgts) && simulationResultRef.current) {
      console.log("should scroll now", simulationResultRef.current)
      requestAnimationFrame(() => {
        simulationResultRef.current.scrollIntoView({ behavior: 'smooth' });
      })
    }
  }, [simulationDataFgts, selectedDataFgts]);

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Novo Atendimento</h3>
                    <h5 className="badge badge-warning">
                      Autorizar no App Meu FGTS: UY3 Sociedade de Crédito
                    </h5>
                    <br />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            CPF
                          </label>
                          <InputMask
                            type="text"
                            name="cpf"
                            // placeholder="CPF"
                            className="form-control form-control-alternative"
                            id="cpf"
                            // placeholder="999.999.999-99"
                            mask="999.999.999-99"
                            onBlur={handleOnBlurCpf}
                            value={attendance.cpf}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.cpf && (
                            <small className="text-danger">
                              {Object.values(errors.cpf)
                                .reduce((t, n) => t + n, '')
                                .split('[')[0] === 'required'
                                ? 'CPF é obrigatório'
                                : `${
                                    Object.values(errors.cpf)
                                      .reduce((t, n) => t + n, '')
                                      .split('[')[0]
                                  }`}
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">
                            Nome
                          </label>
                          <InputMask
                            type="text"
                            name="name"
                            // placeholder="Nome"
                            className="form-control form-control-alternative"
                            id="name"
                            value={attendance.name}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <InputMask
                            type="text"
                            name="email"
                            // placeholder="Email"
                            className="form-control form-control-alternative"
                            id="email"
                            value={attendance.email}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="phone">
                            Telefone
                          </label>
                          <InputMask
                            type="text"
                            name="phone"
                            // placeholder="Celular"
                            className="form-control form-control-alternative"
                            id="phone"
                            mask="(99) 99999-9999"
                            value={attendance.phone}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.phone && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="birth_date"
                            >
                              Data de nascimento
                            </label>
                            <input
                              type="date"
                              name="birth_date"
                              className="form-control form-control-alternative"
                              id="birth_date"
                              value={attendance && attendance.birth_date}
                              ref={register({ required: true })}
                              onChange={handleOnChange}
                            />
                            {errors.birth_date && (
                              <small className="text-danger">
                                A data de nascimento é obrigatória
                              </small>
                            )}
                          </FormGroup>
                        </Col> */}
                      {(!user?.corban_id ||
                        (user?.corban_id && user?.has_proposed_permission)) && (
                        <>
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'master_corban',
                              'user_corban',
                              'regional_manager',
                              'commercial_manager',
                            ]}
                          >
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="store"
                                >
                                  {query.get('isCdc')
                                    ? 'Estabelecimento'
                                    : 'Loja'}
                                </label>
                                <input
                                  className="form-control form-control-alternative"
                                  id="store"
                                  name="store"
                                  value={storeName}
                                  ref={register({ required: true })}
                                  onChange={handleOnChangeStore}
                                  autoComplete="off"
                                />
                                <ul className="corban-list">
                                  {stores &&
                                    stores.map((store) => (
                                      <li
                                        key={store.id}
                                        className="item-list"
                                        onClick={() => {
                                          setStores([]);
                                          setStoreName(store.value);
                                          getSellers(
                                            store.value.match(/^\d+/)?.[0]
                                          );
                                          setStore(
                                            store.value.match(/^\d+/)?.[0]
                                          );
                                          setAttendance((prevState) => ({
                                            ...prevState,
                                            phone_store: store.mobile,
                                          }));
                                        }}
                                      >
                                        {store.value}
                                      </li>
                                    ))}
                                </ul>
                                {errors.store && (
                                  <small className="text-danger">
                                    Loja é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="phone-store"
                                >
                                  Telefone Loja
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone_store"
                                  placeholder="Digite o número da loja"
                                  className="form-control form-control-alternative"
                                  id="phone_store"
                                  mask="(99) 99999-9999"
                                  value={attendance.phone_store}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.phone_store && (
                                  <small className="text-danger">
                                    O número da loja é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </VisibilityByUserRoles>
                          {/* </Row>
                      <Row> */}
                          <VisibilityByUserRoles
                            roles={[
                              'master',
                              'operational',
                              'master_corban',
                              'user_corban',
                              'master_store',
                              'regional_manager',
                              'commercial_manager',
                            ]}
                          >
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="seller"
                                >
                                  Vendedor
                                </label>
                                <CustomInput
                                  type="select"
                                  id="seller"
                                  ref={register({ required: true })}
                                  name="seller"
                                  placeholder="Escolha o Vendedor"
                                  onChange={(e) => setSeller(e.target.value)}
                                  value={seller}
                                >
                                  <option value="">Selecione uma opção</option>
                                  {sellers.map((el) => (
                                    <option key={el.id} value={el.id}>
                                      {el.name}
                                    </option>
                                  ))}
                                </CustomInput>
                                {errors.store && (
                                  <small className="text-danger">
                                    O vendedor é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="phone"
                                >
                                  Telefone Vendedor
                                </label>
                                <InputMask
                                  type="text"
                                  name="phone_seller"
                                  placeholder="Digite o número do vendedor"
                                  className="form-control form-control-alternative"
                                  id="phone_seller"
                                  mask="(99) 99999-9999"
                                  value={attendance.phone_seller}
                                  inputRef={register({ required: true })}
                                  onChange={handleOnChange}
                                />
                                {errors.phone_seller && (
                                  <small className="text-danger">
                                    O número do vendedor é obrigatório
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          </VisibilityByUserRoles>
                        </>
                      )}
                    </Row>
                  </div>
                  {rate && attendance.cpf && (
                    <div className="text-right my-1">
                      <Button
                        disabled={loading}
                        type="button"
                        onClick={() => handleCheckBalance(attendance.cpf)}
                        className="btn btn-success px-4"
                      >
                        <i className="fas fa-paper-plane" /> Consultar Saldo
                      </Button>
                    </div>
                  )}
                  {simulationDataFgts && (
                    <div className="pl-lg-4 ">
                      <Row className="d-flex justify-content-center">
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="deadline"
                            >
                              Quantidade de Parcelas
                            </label>
                            <select
                              className="form-control form-control-alternative"
                              id="deadline"
                              name="deadline"
                              value={deadline}
                              ref={register({ required: true })}
                              onChange={(e) =>
                                handleChangeDeadline(e.target.value)
                              }
                            >
                              <option value="">Selecione uma opção</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <div className="text-right mt-4">
                          <Button
                            disabled={loading}
                            type="button"
                            onClick={() =>
                              getBalance(
                                null,
                                0.0179999999,
                                false,
                                tacs,
                                parseCurrency(attendance.value_client),
                                installmentsSize
                              )
                            }
                            className="btn btn-success px-4 mt-1"
                          >
                            <i className="fas fa-paper-plane" /> Nova Consulta
                          </Button>
                        </div>
                      </Row>
                    </div>
                  )}
                  <Row>
                    {rate && attendance.cpf && parcels && simulationDataFgts && (
                      <Col lg="6 mt-4 ml-4">
                        <Row>
                          <Col>
                            <Row className="d-flex justify-content-center">
                              <Table
                                className="align-items-center justify-content-center table-flush"
                                bordered
                                hover
                                innerRef={simulationResultRef}
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Tabela</th>
                                    <th>Valor Liberado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {simulationDataFgts &&
                                    selectedDataFgts &&
                                    simulationDataFgts.map((item) => {
                                      return (
                                        <TableSimulationFgts
                                          key={item.product_id}
                                          tableName={item.table_name}
                                          productId={item.product_id}
                                          selectTableFgts={setSelectedDataFgts}
                                          selectedTable={selectedDataFgts}
                                          simulationDataFgts={item}
                                          getCommissionSeller={
                                            getCommissionSeller
                                          }
                                        />
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {selectedDataFgts && (
                      <Col lg="5 mt-4" className="container-results ml-4">
                        <Row>
                          <Col lg="6 mt-4" className="">
                            {selectedDataFgts.response.paymentScheduleItems &&
                              selectedDataFgts.response.paymentScheduleItems
                                .length > 0 && (
                                <Table className="align-items-center justify-content-center table-flush">
                                  <thead className="">
                                    <tr>
                                      {/* <th scope="col">Parcelas</th> */}
                                      <th scope="col">
                                        Plano de Pagamentos FGTS
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedDataFgts.response.paymentScheduleItems.map(
                                      (parcel, index) => (
                                        <tr key={index}>
                                          <td>
                                            {index + 1} - {}
                                            {new Date(
                                              parcel.dueDate
                                            ).toLocaleDateString()}
                                          </td>
                                          <td>
                                            {(
                                              parcel.payment / 100
                                            ).toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'BRL',
                                            })}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                    <tr>
                                      <td>
                                        <p className="result-custom-simulator-medium-not-height">
                                          Total
                                        </p>
                                      </td>
                                      <td>
                                        <p className="result-custom-simulator-medium-not-height">
                                          {selectedDataFgts.response.paymentScheduleItems
                                            .map((item) => item.payment / 100)
                                            .reduce(function (a, b) {
                                              return a + b;
                                            })
                                            .toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'BRL',
                                            })}
                                        </p>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                        <td><p className='result-custom-simulator-medium-not-height'>Total Retido de Saldo</p></td>
                                        <td><p className='result-custom-simulator-medium-not-height'>{(parcels.slice(0, deadline[0]).map(item => item.ValorParcela).reduce(function(a, b){return a + b;})).toLocaleString('pt-br',{style: 'currency',currency: 'BRL',})}</p></td>
                                      </tr> */}
                                  </tbody>
                                </Table>
                              )}
                          </Col>
                          <Col lg="6 mt-4" className="">
                            <div className="">
                              <div className="title-result-section text-center">
                                Parcelas
                              </div>
                              <div className="value-result-section installment-section text-center justify-content-center">
                                <span className="result-custom-simulator text-center">
                                  {
                                    selectedDataFgts.response
                                      ?.paymentScheduleItems?.length
                                  }
                                </span>
                              </div>
                            </div>

                            <div className="mt-4">
                              <div className="title-result-section text-center">
                                Valor Liberado{' '}
                                {query.get('isCdc') && 'Estabelecimento'}
                              </div>
                              <div className="value-result-section justify-content-center">
                                <CurrencyInput
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  prefix="R$"
                                  name="value_free"
                                  // placeholder="999.00"
                                  className="form-control result-custom-simulator text-center"
                                  id="value_free"
                                  disabled
                                  value={
                                    selectedDataFgts.response.liquidValue / 100
                                  }
                                  // inputRef={register({ required: true })}
                                  // onChange={handleOnChangeValueFree}
                                />
                              </div>
                            </div>

                            <Table className="align-items-center justify-content-center table-flush">
                              <tbody>
                                {commissionSeller != null &&
                                  !query.get('isCdc') && (
                                    <tr>
                                      <td style={{ padding: 0, fontSize: 18 }}>
                                        <p className="title-result-section-medium text-center text-success">
                                          Comissão
                                        </p>
                                      </td>
                                      <td style={{ padding: 0 }}>
                                        <p className="text-success title-result-section-medium text-right">
                                          {parseFloat(
                                            commissionSeller
                                          ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}
                                          (
                                          {getPercentValue(
                                            commissionSeller,
                                            selectedDataFgts.response
                                              .liquidValue / 100
                                          )}
                                          %)
                                        </p>
                                      </td>
                                    </tr>
                                  )}

                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-orange text-center">
                                      Saldo Retido
                                    </p>
                                  </td>
                                  <td style={{ padding: 0 }}>
                                    <p className="title-result-section-medium text-right text-orange">
                                      {selectedDataFgts.response.paymentScheduleItems
                                        .map((item) => item.payment / 100)
                                        .reduce(function (a, b) {
                                          return a + b;
                                        })
                                        .toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL',
                                        })}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>

                  {selectedDataFgts && (
                    <div className="text-right mt-6">
                      <Button
                        disabled={loading}
                        type="submit"
                        className="btn btn-success px-4"
                      >
                        <i className="fas fa-paper-plane" /> Continuar
                      </Button>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateCustomerServiceFgtsUy3;
