import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import ContractForm from '../../components/ContractForm';
import token from '../../util/setAuthToken';
import { useLocation } from 'react-router-dom';

const DocumentUploadCustomerUy3 = (props) => {
  const { id, handleSubmit } = props;
  const [contractSignFile, setContractSignFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [frontFile, setFrontFile] = useState(null);
  const [facematchFile, setFacematchFile] = useState(null);
  const [contractSignFileUrl, setContractSignFileUrl] = useState(null);
  const [frontFileUrl, setFrontFileUrl] = useState(null);
  const [backFileUrl, setBackFileUrl] = useState(null);
  const [facematchFileUrl, setFacematchFileUrl] = useState(null);

  async function onSubmit() {
    // if (documentRg.length == 0 && documentRgPersist == 0) {
    //   toastError('Selecione pelo menos uma foto para cada opção');
    //   return;
    // }

    handleSubmit(contractSignFile, backFile, frontFile, facematchFile);
    console.log(contractSignFile);
  }

  useEffect(() => {
    getCustomerData();
  }, []);

  async function getCustomerData() {
    try {
      let { data } = await api.get(`contracts/${id}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      console.log(data);

      setContractSignFileUrl(data.contract_sign_file);
      setBackFileUrl(data.back_file);
      setFrontFileUrl(data.front_file);
      setFacematchFileUrl(data.facematch_file);
    } catch (e) {
      console.log(e);
    }
  }

  const handleOnChangeContractSignFile = (e) => {
    let files = e.target.files;
    setContractSignFile(files[0]);
  };

  const handleOnChangeFrontFile = (e) => {
    let files = e.target.files;
    setFrontFile(files[0]);
  };

  const handleOnChangeBackFile = (e) => {
    let files = e.target.files;
    setBackFile(files[0]);
  };

  const handleOnChangeFacematchFile = (e) => {
    let files = e.target.files;
    setFacematchFile(files[0]);
  };

  return (
    <>
      <CardBody>
        <>
          <Row>
            <Col lg="8">
              <label className="form-control-label" htmlFor="auto">
                Contrato Assinado
              </label>
            </Col>
            {contractSignFileUrl ? (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    href={contractSignFileUrl}
                    target="_blank"
                    className="btn btn-info"
                  >
                    Baixar/Visualizar Contrato Assinado
                  </a>
                </FormGroup>
              </Col>
            ) : (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    className="btn btn-danger"
                  >
                    Documento não anexado
                  </a>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="auto">
                  Documento Frente
                </label>
              </FormGroup>
            </Col>
            {frontFileUrl ? (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    href={frontFileUrl}
                    target="_blank"
                    className="btn btn-info"
                  >
                    Baixar/Visualizar Documento Frente
                  </a>
                </FormGroup>
              </Col>
            ): (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    className="btn btn-danger"
                  >
                    Documento não anexado
                  </a>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="auto">
                  Documento Verso
                </label>
              </FormGroup>
            </Col>
            {backFileUrl ? (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    href={backFileUrl}
                    target="_blank"
                    className="btn btn-info"
                  >
                    Baixar/Visualizar Documento Verso
                  </a>
                </FormGroup>
              </Col>
            ): (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    className="btn btn-danger"
                  >
                    Documento não anexado
                  </a>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="auto">
                  Selfie
                </label>
              </FormGroup>
            </Col>
            {facematchFileUrl ? (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    href={facematchFileUrl}
                    target="_blank"
                    className="btn btn-info"
                  >
                    Baixar/Visualizar Selfie
                  </a>
                </FormGroup>
              </Col>
            ) : (
              <Col
                lg="4"
                className="d-flex col-lg-4 align-items-center justify-content-center"
              >
                <FormGroup>
                  <a
                    className="btn btn-danger"
                  >
                    Documento não anexado
                  </a>
                </FormGroup>
              </Col>
            )}
          </Row>
        </>

       
        {/* </Form> */}
      </CardBody>
    </>
  );
};

export default DocumentUploadCustomerUy3;
