import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input';

import InputMask from 'react-input-mask';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import getToken from '../../util/setAuthToken';
import ContractForm from '../../components/ContractForm';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';

import ReactToPrint from 'react-to-print';
import DetailContractForm from './form';

import './style.css';
import { handleBeforeUnloadDetail } from 'components/Loading';

const DetailContract = (props) => {
  const componentRef = useRef();
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [submit, setSubmit] = useState(true);
  const [disabledRequest, setDisabledRequest] = useState(false);
  const [fileOriginal, setFileOriginal] = useState(null);
  const [alreadyPageLog, setAlreadyPageLog] = useState('');
  const [pageType, setPageType] = useState('detalhes_de_contrato');

  const { user } = useContext(AuthContext);

  const [banks, setBanks] = useState([]);
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    birth_date: '',
    cpf_cnpj: '',
    mobile: '',
    zipcode: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    contract_number: '',
    contract_value: '',
    last_contract_value: '',
    amount_charged: '',
    last_amount_charged: '',
    installments: '',
    idcustomer: '',
    idpartner: '',
    tac_table_name: '',
  });

  async function getCustomerData() {
    try {
      let { data } = await api.get(`contracts/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      getPageLog();
      setCustomer({
        ...customer,
        contract_number: data.contract_number,
        contract_value: parseMaskCurrency(data.contract_value),
        liquid_value: parseMaskCurrency(data.liquid_value),
        last_contract_value: parseMaskCurrency(data.contract_value),
        amount_charged: parseMaskCurrency(data.amount_charged),
        last_amount_charged: parseMaskCurrency(data.amount_charged),
        idcustomer: data.id,
        has_signed_ccb_file: data.has_signed_ccb_file,
        signed_ccb_file: data.signed_ccb_file,
        installments: data.installments,
        corban_name: data.corban_name,
        sub_name: data.sub_name,
        kind: data.kind,
        mother_name: data.mother_name,
        father_name: data.father_name,
        store_name: data.store_name,
        seller_name: data.seller_name,
        user_manager_name: data.user_manager_name,
        basic_interest: data.basic_interest,
        name: data.customer.name,
        birth_date: data.customer.birth_date,
        cpf_cnpj: data.customer.cpf_cnpj,
        rg: data.customer.rg,
        mobile: data.customer.mobile,
        email: data.customer.email,
        zipcode: data.customer?.address?.zip_code,
        street: data.customer?.address?.street,
        number: data.customer?.address?.number,
        id: data.id,
        district: data.customer?.address?.district,
        city: data.customer?.address?.city,
        state: data.customer?.address?.state,
        complement: data.customer?.address?.complement,
        bank_id: data.customer.bank_account
          ? data.customer.bank_account.id
          : null,
        bank_name: data.customer.bank_account
          ? `${
              data.customer.bank_account.number_bank
                ? data.customer.bank_account.number_bank
                : '001'
            } - ${
              data.customer.bank_account.name_bank
                ? data.customer.bank_account.name_bank
                : 'Banco não selecionado'
            }`
          : 'Banco não selecionado',
        agency_account: data.customer.bank_account
          ? data.customer.bank_account.agency_account
            ? data.customer.bank_account.agency_account
            : '0000'
          : '0000',
        agency_digit: data.customer.bank_account
          ? data.customer.bank_account.agency_digit
            ? data.customer.bank_account.agency_digit
            : '0'
          : '0',
        account_digit: data.customer.bank_account
          ? data.customer.bank_account.account_digit
            ? data.customer.bank_account.account_digit
            : '0'
          : '0',
        number_account: data.customer.bank_account
          ? data.customer.bank_account.number_account
            ? data.customer.bank_account.number_account
            : '0000'
          : '0000',
        account_kind: data.customer.bank_account
          ? data.customer.bank_account.kind
          : null,
        kind_pix: data.customer.bank_account
          ? data.customer.bank_account.kind_pix
          : null,
        pix: data.customer.bank_account ? data.customer.bank_account.pix : null,
        month: data.month,
        kind_credit_card: data.kind_credit_card,
        tac_table_name: data.tac_table_name,
        mobile_seller: data?.mobile_seller,
        mobile_store: data?.mobile_store,
      });
      hideLoader();
    } catch (e) {
      console.log(e);
      hideLoader();
    }
  }

  async function getPageLog() {
    try {
      let { data } = await api.get(`page_log/${pageType}/${id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const res = data.filter(
        (item) => item.page_type === pageType && item.user_id !== user?.id
      );

      if (res.length > 0) {
        setAlreadyPageLog(
          `Usuário ${res[0].name} está logado nesta página. Favor aguardar.`
        );
        // setDisabledRequest(true);
      }

      const result = data.filter((item) => item.page_type === pageType);
      if (result.length === 0) {
        await api.get(
          `contracts/${id}?name=${user.name}&page_type=${pageType}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
      }
    } catch (e) {
      console.log(e);
      hideLoader();
    }
  }

  useEffect(() => {
    showLoader();
    getDocumentOriginal(id);
    getCustomerData();
    localStorage.setItem('page_log', 'detalhes_do_contrato');
  }, []);

  useEffect(() => {
    if (user) {
      // document.addEventListener('visibilitychange', function logData() {
      //   if (document.visibilityState === 'hidden') {
      //     navigator.sendBeacon(
      //       `https://nsaque.ultragate.com.br/api/v1/page_log_destroy_open/${pageType}?user_id=${user.id}`,
      //       'Encerrar'
      //     );
      //   }
      // });
    }
  }, [user]);

  function onChange(e) {
    if (
      e.target.name === 'contract_value' ||
      e.target.name === 'amount_charged'
    ) {
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeContractValue(e) {
    setCustomer({
      ...customer,
      contract_value: parseCurrency(e),
    });
  }

  function handleOnChangeAmountCharged(e) {
    setCustomer({
      ...customer,
      amount_charged: parseCurrency(e),
    });
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parseMaskCurrency(value) {
    return value.toString().replace('.', ',');
  }

  async function onSubmit(data) {
    const amountCharged = Number(parseCurrency(data.amount_charged));
    const contractValue = Number(parseCurrency(data.contract_value));

    if (amountCharged <= contractValue) {
      toastError(
        'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
      );
      return;
    }

    if (submit) {
      if (amountCharged <= contractValue) {
        toastError(
          'Valor Cobrado não pode ser menor ou igual ao Valor do Contrato.'
        );
        return;
      }

      showLoader();

      try {
        await api.put(`contracts/${id}`, JSON.stringify(customer), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
        });

        hideLoader();

        toastSuccess('Contrato atualizado com sucesso');

        props.history.push('/admin/contracts');
      } catch (error) {
        hideLoader();

        let message =
          'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

        if (error.response) {
          if (error.response.data.errors) {
            message = error.response.data.errors;

            message.forEach((e) => {
              toastError(e.msg);
            });

            return;
          }
          if (error.response.data) {
            message = error.response.data.message;
          }

          if (typeof message === 'undefined') {
            message =
              'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';
          }
        }

        toastError(message);
      }
    }
  }

  async function handleOnClick() {
    const amountCharged = Number(
      formatCurrency(customer.amount_charged.toString())
    );
    const lastAmountCharged = Number(
      formatCurrency(customer.last_amount_charged.toString())
    );

    const lastContractValue = Number(
      formatCurrency(customer.last_contract_value.toString())
    );
    const contractValue = Number(
      formatCurrency(customer.contract_value.toString())
    );

    if (
      amountCharged !== lastAmountCharged ||
      lastContractValue !== contractValue
    ) {
      setSubmit(false);

      swal({
        title: 'Atenção',
        text: `Contrato alterado. Vamos enviar um novo link de pagamento para o Cliente, confirma a alteração?`,
        icon: 'warning',
        buttons: ['Não', 'Sim'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSubmit(true);
          onSubmit(customer);
        }
      });
    }
  }
  async function getDocumentOriginal(id) {
    try {
      const { data } = await api.get(
        `/contracts/${id}/get_contract_clicksign_original`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setFileOriginal(data);
    } catch (error) {
      console.log('Algo deu errado ao buscar documento');
    }
  }

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          setCustomer({
            ...customer,
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
        } else {
          setCustomer({
            ...customer,
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="8">
                    <h3 className="mb-0">Detalhes do Contrato</h3>
                  </Col>
                  <Col lg="4" className="mt-3 mt-lg-0">
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return (
                          // <Button color="info" disabled={disabledRequest}>
                          <Button color="info">Imprimir</Button>
                        );
                      }}
                      content={() => componentRef.current}
                    />
                    {/* {
                      customer.kind == 'fgts' && fileOriginal && (
                        <a className='btn btn-primary' target="_blank" href={fileOriginal}>
                          Baixar CCB
                        </a>
                      )
                    } */}
                    {customer.kind == 'fgts' && customer.has_signed_ccb_file && (
                      <a
                        className="btn btn-primary"
                        target="_blank"
                        href={`/ccb_download/${customer.id}`}
                      >
                        Baixar CCB assinada
                      </a>
                    )}
                  </Col>
                  {/* {alreadyPageLog !== '' && (
                    <h5
                      className="badge badge-warning"
                      style={{ marginLeft: 16 }}
                    >
                      {alreadyPageLog}
                    </h5>
                  )} */}
                </Row>
              </CardHeader>
              <DetailContractForm
                customer={customer}
                banks={banks}
                ref={componentRef}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailContract;
