import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import token from '../../../util/setAuthToken';
import { toastError, toastSuccess } from '../../../util/toast';
import { LoadingContext } from '../../../context/loading/LoadingContext';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

import BankForm from './bank_form';
import { create } from 'nouislider';

const LIMIT = 10;

const CreateBankForm = ({ bank, setIsOpen, isOpen, banks, setBanks }) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [payload, setPayload] = useState({
    id: '',
    name: '',
    code: '',
    has_fgts: false,
    has_credit_card: false,
    has_partners: false
  });

  async function onSubmit(data) {
    showLoader();

    data = {
      bank: {
        code: payload.code,
        name: payload.name,
      },
    };

    try {
      const { data } = await api.post(
        `banks`,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      let createdBank = data

      hideLoader();

      toastSuccess('Banco criado com sucesso');

      setIsOpen(false);
    } catch (error) {
      hideLoader();
      console.log(error)

      let message =
        'Ops, tivemos um erro na criação do banco, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;
          console.log(message);
          message.forEach((e) => {
            toastError(e);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }

        if (typeof message === 'undefined') {
          message =
            'Ops, tivemos um erro na criação do cliente, por favor tente novamente!';
        }
      }

      toastError(message);
    }
  }

  function handleOnChange(e) {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  }

  function handleOnChangeCheckbox(e) {
    setPayload({ ...payload, [e.target.name]: e.target.checked });
  }

  return (
    <>
      <BankForm
        bank={payload}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
        errors={errors}
        titleButton="Criar Banco"
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
};
export default CreateBankForm;
