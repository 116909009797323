import React, { useContext, useState, useEffect } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import ReferrerHeader from '../../components/Headers/ReferrerHeader';
import ReferrerForm from '../../components/ReferrerForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';

const CreateReferrer = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const query = new URLSearchParams(useLocation().search);
  const [referrer, setReferrer] = useState({
    name: '',
    birth_date: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    has_commission: '',
    commission_type: '',
    value: '',
    social_reason: '',
  });

  const [ banks, setBanks ] = useState([])

  useEffect(()=> {
    async function getBanks() {
      showLoader()

      try {
        const response = await api.get(
          `banks?q[active_eq]=true&q[has_partners_eq]=true`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        const values = response.data.map((value,index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`
          }
        })

        setBanks(values);

        hideLoader();

      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }

    getBanks();
  }, [])

  const isValidEmail = email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  const isValidCPF = cpf => /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(
    cpf
  );
  const isValidCPFNumber = cpf => /^\d{11}$/.test(
    cpf
  );
  const isValidPhoneNumber = phone => /^\d{11}$/.test(
    phone
  );
  const isValidPhone = phone => /^\d{10}$/.test(
    phone
  );
  async function onSubmit(data) {
    showLoader();


    if(!referrer.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(referrer.kind_pix != 'random' && !referrer.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }

    if(referrer.kind_pix == 'email'){
      if(!isValidEmail(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }

    if(referrer.kind_pix == 'cpf_cnpj'){
      if(!isValidCPF(referrer.pix) && !isValidCPFNumber(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }

    if(referrer.kind_pix == 'phone'){
      if(!isValidPhoneNumber(referrer.pix) && !isValidPhone(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }

    data = {
      referrer: {
        birth_date: referrer.birth_date,
        email: referrer.email,
        mobile: referrer.mobile,
        rg: referrer.rg,
        mother_name: referrer.mother_name,
        father_name: referrer.father_name,
        social_reason: referrer.social_reason,
        entity_attributes: {
          name: referrer.name,
          cpf_cnpj: referrer.cpf_cnpj.replace(/[^0-9]+/g, ''),
          has_commission: referrer.has_commission == "yes" ? true : false,
          address_attributes: {
            zip_code: referrer.zip_code,
            street: referrer.street,
            number: referrer.number,
            district: referrer.district,
            city: referrer.city,
            state: referrer.state,
            complement: referrer.complement,
          },
          bank_account_attributes: {
            number_bank: referrer.bank_name.split('-')[0],
            name_bank: referrer.bank_name.split('-')[1],
            agency_account: referrer.agency_account,
            agency_digit: referrer.agency_digit,
            number_account: referrer.number_account,
            account_digit: referrer.account_digit,
            kind: referrer.account_kind,
            kind_pix: referrer.kind_pix,
            pix: referrer.pix,
            month: referrer.month ? referrer.month : "",
          },
          commission_bases_attributes: [
            {
              commission_type: parseInt(referrer.commission_type,10),
              value: referrer.value ? referrer.value.toString().replace('R$', '').replace(',', '.') : referrer.value,
            }
          ]
        }
      }
    }

    let params =  ""
    if(query.get("referrerServiceId")) {
      params = `?referrer_service_id=${query.get("referrerServiceId")}`
    }

    try {
      await api.post(`referrers${params}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Indicador criado com sucesso');

      if(query.get("referrerServiceId")) {
        swal({
          title: 'Atendimento',
          text:
            'Deseja realizar agora o contrato?',
          icon: 'success',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        }).then(async (next) => {
          if (next) {
            props.history.push(`/admin/contracts/create?referrerServiceId=${query.get("referrerServiceId")}`);
          }else {
            props.history.push('/admin/referrers');
          }
        });
      }else{
        props.history.push('/admin/referrers');
      }



    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error
          responseErrors.forEach((e) => {
            console.log(e.key)
            console.log(e.model)
            if (e.key == "Documento" && e.model == "entity"){
              setError("cpf_cnpj", 'inválido')
            } else if (e.key == "Nascimento" && e.model == "Nascimento") {
              setError("birth_date", 'Idade inválida. (min. 18, max. 100)')
            }
          });
        }
      }
      window.scrollTo(0, 0)
      toastError("Há informações incorretas, por favor revise-as.");
    }
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    if (e.target.name == "agency_digit") {
      setReferrer ({ ...referrer, ["agency_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "account_digit") {
        setReferrer ({ ...referrer, ["account_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "number_account") {
        setReferrer ({ ...referrer, ["number_account"] : parseAccount(e.target.value) });
      } else if (e.target.name == "cpf_cnpj") {
        if ( e.target.value.length < 15 ) {
          let cpf_cnpj = e.target.value
          if(cpf_cnpj.length >= 12) {
            cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
          } else {
            cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
          }
          setReferrer ({ ...referrer,  [e.target.name]: cpf_cnpj});
        }
      } else if (e.target.name == "mobile") {
          if ( e.target.value.length < 16 ) {
            let mobile = e.target.value

            if(mobile.length > 10) {
              mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
            } else {
              mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
            }
            setReferrer ({ ...referrer,  [e.target.name]: mobile});
          }
      } else if (e.target.name == "value_percentage") {
          console.log(e.target.value)
          setReferrer({ ...referrer, ["value"] : e.target.value });
      } else {
        setReferrer({ ...referrer, [e.target.name]: e.target.value });
      }
    }

  function handleOnChangeBank(e){
    setReferrer({ ...referrer, 'bank_name': e.value })
  }

  function dataAddress({ street, district, city, state }) {
    setReferrer({
      ...referrer,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  function insertMoney(e) {
    if(e.target.value.charAt(0) != 'R') {
      setReferrer({ ...referrer, ["value"]: `R$ ${e.target.value}` });
    }
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function handleOnChangeValueFix(e){
    setReferrer({
      ...referrer,
      value: parseCurrency(e)
    })
  }

  return (
    <>
      <ReferrerHeader />
      <ReferrerForm
        banks={banks}
        referrer={referrer}
        title="Criar Indicador"
        titleButton="Criar Indicador"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        dataAddress={dataAddress}
        register={register}
        handleSubmit={handleSubmit}
        handleOnChangeBank={handleOnChangeBank}
        insertMoney={insertMoney}
        handleOnChangeValueFix={handleOnChangeValueFix}
        errors={errors}
      />
    </>
  );
};

export default CreateReferrer;
