import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput,
  Button,
} from 'reactstrap';
import Pagination from 'react-responsive-pagination';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { AuthContext } from '../../context/AuthContext';
import { VisibilityByUserRoles } from '../../util/visibility';
import InputMask from 'react-input-mask';
import './style.css';
import Search from '../../components/Search';

const LIMIT = 12;

const Customers = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState([]);
  const params = new URLSearchParams(props.location.search);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });
  const [pages, setPages] = useState(1);
  const [cpf, setCpf] = useState('');

  const handleToggleFilter = () => setFilterModal(!filterModal);
  const [filterModal, setFilterModal] = useState(false);
  const [filterBlack, setFilterBlack] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterName, setFilterName] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [numberRegister, setNumberRegister] = useState('')

  const [reportModal, setReportModal] = useState(false);
  const [emailReport, setEmailReport] = useState("");
  const [completeReportDisabled, setCompleteReportDisabled] = useState(false);

  async function getCustomers() {
    showLoader();

    try {
      const response = await api.get(
        `customers?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );
      console.log(response.data)
      setCustomers(response.data);
      setNumberRegister(response.headers.total_count)

      const totalPages = response.headers.total_pages

      setPages(totalPages);

      if (offset > 1) {
        props.history.push({
          pathname: '/admin/customers',
          search: `?page=${offset}`,
        });
      } else {
        params.delete('page');
        props.history.replace({
          search: params.toString(),
        });
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os cliente.');
    }
  }

  useEffect(() => {

    getCustomers();
  }, [offset, searchFilter]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [offset]);

  function titleCase(str) {
    if(!str){
      return
    }
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  async function handleOnClick() {

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `customers?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        console.log(data)
        setCustomers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    } else {
      toastError('Por favor preencha o campo para buscar um CPF.');
    }
  }

  async function retrieveDataAsynchronously(searchText) {
    try {
      const { data } = await api.get(`customers?q[entity_cpf_cnpj_cont]=${searchText}`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setAutoCompleteData(data);
    } catch (e) {
      console.error('Ops, houve um erro na busca pelo CPF');
    }
  }

  async function onChange(e) {
    setCpf(e.target.value);
    await retrieveDataAsynchronously(e.target.value);
  }

  function onSelect(val) {
    setCpf(val);
  }

  function renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item.cpf_cnpj} - {item.name}
      </div>
    );
  }

  function getItemValue(item) {
    return `${item.cpf_cnpj}`;
  }

  async function handleOnClickBlackList(idcustomer, active) {
    showLoader();
    try {

      const { data } = await api.put(
        `customers/${idcustomer}`,
        { customer: {blacklist: !active}},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      getCustomers()

      hideLoader();

      toastSuccess(data.message);
    } catch (e) {
      hideLoader();
      let message = 'Algo deu errado ao incluir ou retirar da BlackList';
      if (e.response.data) {
        message = e.response.data.message;
      }
      toastError(message);
    }
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }
    let filterPath = '';

    if (cpf.length > 0) {
      try {
        const { data } = await api.get(
          `customers?page=${offset}&per_page=${LIMIT}&q[entity_cpf_cnpj_eq]=${cpf}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );
        setCustomers(data);
        setPages(1);
      } catch (e) {
        let message = 'Ops, não foi possível buscar um CPF.';

        if (e.response.data) {
          message = e.response.data.message;
        }

        toastError(message);
      }
    }

    if (filterBlack) {
      filterPath += `&q[blacklist_eq]=${filterBlack}`;
    }

    if (filterEmail) {
      filterPath += `&q[email_cont]=${filterEmail}`;
    }

    if (filterName) {
      filterPath += `&q[entity_name_cont]=${filterName}`;
    }
    hideLoader();
    setSearchFilter(filterPath);
    handleToggleFilter();
  }

  function handleClearFilter() {
    setFilterName('')
    setFilterEmail('')
    setFilterBlack('')
    setSearchFilter('')
    handleToggleFilter()
  }

  const handleToggleReportModal = () => {
    setReportModal(!reportModal)
  }

  async function handlerOnClickReportModal() {

    try {
      await api.get(`/customers/export_data_background?email=${emailReport}&${searchFilter}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      toastSuccess('Relatorio Solicitado com sucesso, aguarde o envio por email');

    } catch (error) {
      hideLoader();
      let message = '';

      if (error.response.data.error) {
        message = error.response.data.error;
        message.forEach((e) => {
          toastError(e);
        });
      } else if (error.response.data) {
        message = error.response.data.message;
      } else {
        message =
          'Ops, tivemos um erro ao solicitar relatorio completo, por favor tente novamente!';
      }

      toastError(message);
    }
    setReportModal(!reportModal)
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              {
                user && user.kind != 'supervisor_commmercial_internal' && (
                  <div className="text-right">
                    <Link
                      className="btn btn-success mb-3 mt-2"
                      to="/admin/customers/create"
                    >
                      <i className="fas fa-plus" /> Criar novo cliente
                    </Link>
                  </div>
                )
              }

              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Clientes</h3>
                      {/* <Search
                        getItemValue={getItemValue}
                        autoCompleteData={autocompleteData}
                        renderItem={renderItem}
                        cpf_cnpj={cpf}
                        onChange={onChange}
                        onSelect={onSelect}
                        handleOnClick={handleOnClick}
                        placeHolder={"Buscar pelo CPF"}
                      /> */}
                    </Col>
                    <Col>
                      {
                        <>
                          <div className="d-flex justify-content-end h-100 align-items-end">
                            <VisibilityByUserRoles
                              roles={[
                                'master',
                                'operational',
                                'commercial_manager',
                                'regional_manager',
                                // 'supervisor_commmercial_internal',
                                'commercial_manager_internal',
                                'superintendent_commercial',
                                'master_corban',
                                'master_subest',
                                'master_store',
                              ]}
                            >
                              <>
                                <button
                                  disabled={completeReportDisabled}
                                  className="btn btn-primary"
                                  onClick={() => handleToggleReportModal()}
                                >
                                  Relatório email
                                </button>
                              </>
                            </VisibilityByUserRoles>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleToggleFilter}
                            >
                              <i className="fas fa-filter" /> Filtrar
                            </button>
                          </div>
                        </>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CPF</th>
                      <th scope="col">Nome</th>
                      <th scope="col">E-Mail</th>
                      <th scope="col">Telefone</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer) => (
                      <tr
                        key={customer.id}
                        className={customer.blacklist ? 'blacklist' : ''}
                      >
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {customer.entity.cpf_cnpj.replace(
                                  /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/,
                                  '$1.$2.$3-$4'
                                )}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{titleCase(customer.entity.name)}</td>
                        <td>{customer.email}</td>
                        <td scope="row">
                          {customer.mobile.replace(
                            /^([\d]{2})([\d]{1})([\d]{4})([\d]{4})$/,
                            '($1) $2 $3-$4'
                          )}
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link
                                to={`/admin/contracts?customer_id=${customer.id}`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-file-contract" /> Ver
                                  Contratos
                                </span>
                              </Link>
                              <Link
                                to={`/admin/customers/${customer.id}`}
                                className="dropdown-item"
                              >
                                <span>
                                  <i className="fas fa-edit" /> Editar cliente
                                </span>
                              </Link>
                              {user &&
                              (user.roles[0].name == 'master' ||
                                user.roles[0].name == 'operational') ? (
                                <DropdownItem
                                  onClick={() =>
                                    handleOnClickBlackList(
                                      customer.id,
                                      customer.blacklist
                                    )
                                  }
                                >
                                  <span>
                                    <i className="fas fa-user-lock" />{' '}
                                    {customer.blacklist ? 'Retirar' : 'Incluir'}{' '}
                                    BlackList
                                  </span>
                                </DropdownItem>
                              ) : (
                                false
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={filterModal}
        toggle={handleToggleFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="CPF">
                    CPF
                  </label>
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    id="cpf"
                    name="cpf"
                    placeholder="Digite o CPF"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="blacklist">
                    Blacklist
                  </Label>
                  <CustomInput
                    type="select"
                    id="blacklist"
                    name="blacklist"
                    onChange={(e) => setFilterBlack(e.target.value)}
                    value={filterBlack}
                  >
                    <option value="">Todos</option>
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Digite o Email"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Nome"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClearFilter}>
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={handleToggleFilter}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reportModal}
        toggle={handleToggleReportModal}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={handleToggleReportModal}>
          Insira o email para solicitar o relatorio
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email_report">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email_report"
                    name="email_report"
                    placeholder="Digite o Email"
                    onChange={(e) => setEmailReport(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleToggleReportModal}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickReportModal}>
            Solicitar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Customers;
