import React, { useState, useEffect, useContext, useRef } from 'react';

import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';

const BankForm = ({
  bank,
  onSubmit,
  register,
  handleSubmit,
  handleOnChange,
  handleOnChangeCheckbox,
  errors,
  titleButton,
  setIsOpen,
  isOpen,
}) => {
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Container>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="code">
                    Código
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-alternative"
                    id="code"
                    name="code"
                    value={bank && bank.code}
                    ref={register({
                      required: 'É obrigatório',
                      pattern: {
                        value: /^\d+$/,
                        message: 'Deve conter apenas números',
                      },
                    })}
                    onChange={handleOnChange}
                  />

                  {errors.code && (
                    <small className="text-danger">{errors.code.message}</small>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-alternative"
                    id="name"
                    name="name"
                    value={bank && bank.name}
                    ref={register({
                      required: 'É obrigatório',
                    })}
                    onChange={handleOnChange}
                  />

                  {errors.name && (
                    <small className="text-danger">{errors.name.message}</small>
                  )}
                </FormGroup>
                <label className="form-control-label" htmlFor="kind">
                  Tipo Cadastro
                </label>
                <Row className='ml-3'>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_fgts"
                        checked={bank && bank.has_fgts}
                        value={bank && bank.has_fgts}
                        onChange={handleOnChangeCheckbox} />
                      FGTS
                    </Label>
                  </Col>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_credit_card"
                        checked={bank && bank.has_credit_card}
                        value={bank && bank.has_fgts}
                        onChange={handleOnChangeCheckbox} />
                      Cartão de Crédito
                    </Label>
                  </Col>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_partners"
                        checked={bank && bank.has_partners}
                        value={bank && bank.has_fgts}
                        onChange={handleOnChangeCheckbox} />
                      Parceiros
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="btn btn-success px-4">
            <i className="fas fa-paper-plane" /> {titleButton}
          </Button>
          <Button color="danger" onClick={() => setIsOpen(!isOpen)}>
            Fechar
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};
export default BankForm;
