import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { AuthContext } from '../../context/AuthContext';
import token from '../../util/setAuthToken';
import api from '../../services/api';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import Select from 'react-select';

const CreateUser = (props) => {
  const user = JSON.parse(localStorage.getItem('@UGate:user'))
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [createUser, setCreateUser] = useState({
    id: '',
    name: '',
    email: '',
    mobile: '',
    birth_date: '',
    password: '',
  });

  // const { user } = useContext(AuthContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [stores, setStores] = useState([])
  const [subestablishment, setSubestablishment] = useState([])
  const [visibility_sub_select, setVisibilitySubSelect] = useState([])
  const [visibility_store_select, setVisibilityStoerSelect] = useState([])

  const [regionalManagers, setRegionalManager] = useState([])
  const [commercialManagers, setCommercialManagers] = useState([])

  function handleOnChange(e) {
    setCreateUser({ ...createUser, [e.target.name]: e.target.value });
  }

  async function getRegionalManager() {
    try {
      const response = await api.get(
        `users?q[roles_name_eq]=regional_manager`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          }
        }
      )
      setRegionalManager(response.data)
    } catch(error) {
      toastError('Ops, não foi possível lista os gerentes regionais.');
    }
  }

  async function getCommercialManager(){
    try {
      const response = await api.get(
        `users?q[roles_name_eq]=commercial_manager_internal`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          }
        }
      )
      setCommercialManagers(response.data)
    } catch(error) {
      toastError('Ops, não foi possível lista os gerentes comerciais internos.');
    }
  }

  async function getSubestablishment() {
    try {
      const response = await api.get(
        `subestablishments`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value) => {
        return {
          value: value.id,
          label: value.entity.name
        }
      })

      setSubestablishment(values)
    } catch(error) {
      toastError('Ops, não foi possível lista os subestabelecimentos.');
    }
  }

  async function getStore() {

    try {
      const response = await api.get(
        `stores`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value) => {
        return {
          value: value.id,
          label: value.entity.name
        }
      })
      setStores(values)
    } catch (error) {
      toastError('Ops, não foi possível lista as lojas.');
    }
  }

  function handleOnChangeCheck(e) {
    setCreateUser({ ...createUser, [e.target.name]: e.target.checked });
  }

  function handleOnChangeSelectSub(e) {
    setVisibilitySubSelect(e.map((item) => item.value));
  }

  function handleOnChangeSelectStore(e) {
    setVisibilityStoerSelect(e.map((item) => item.value))
  }

  useEffect(() => {
    async function getUsers() {
      showLoader();
      try {
        const { data } = await api.get(`users/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        let userKind = null

        if (!["corban", "subestablishment", "store", "seller"].includes(data.kind)) {
          userKind = data.kind
        }

        console.log(data.manager_id)

        setCreateUser({
          ...createUser,
          id: data.id,
          name: data.name,
          cpf: data.cpf,
          regional_manager: data.manager_id,
          intern_role: userKind,
          birth_date:  moment(data.birth_date).format(
            'DD/MM/YYYY'
          ),
          mobile: data.mobile,
          email: data.email
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }

    if(user.kind != "seller" && user.kind != "store") {
      getStore();
      if (user.kind != "subestablishment") {
        getSubestablishment();
      }
    }
    if (user.kind == "master") {
      getRegionalManager()
      getCommercialManager()
    }

    getUsers();
  }, []);

  async function onSubmit(event) {
    // event.preventDefault();
    // event.stopPropagation();

    showLoader();
    console.log(event)
    try {
      let data = {}

      let userData = {
        name: createUser.name,
        email: createUser.email,
        mobile: createUser.mobile,
        birth_date: createUser.birth_date,
        cpf: createUser.cpf
      }

      if(createUser.regional_manager) {
        userData = {
          ...userData,
          manager_id: createUser.regional_manager
        }
      }

      if(createUser.password) {
        userData = {
          ...userData,
          password: createUser.password
        }
      }

      if(createUser.intern_role) {
        data = {
          ...data,
          intern_role: createUser.intern_role,
          intern: createUser.intern_role != '' ? true : false
        }
      }

      await api.put(
        `users/${id}`,
        JSON.stringify({user: userData, ...data}),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      hideLoader();

      toastSuccess('Dados atualizado com sucesso.');

      props.history.push('/admin/users');
    } catch (error) {
      console.log(error)
      hideLoader();

      let message = null;

      if (error.response) {
        if (error.response.data.errors) {
          message = error.response.data.errors;

          message.forEach((e) => {
            toastError(e.msg);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }
      } else {
        message =
          'Ops, não foi possível atualizar seus dados, por favor tente novamente';
      }
      toastError(message);
    }
  }

  return (
    <>
      <CustomerHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Cadastrar Usuario</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações pessoais
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Nome
                          </label>
                          <InputMask
                            type="text"
                            name="name"
                            placeholder="Nome"
                            className="form-control form-control-alternative"
                            id="name"
                            value={createUser.name}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O nome é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <InputMask
                            type="text"
                            name="email"
                            placeholder="Email"
                            className="form-control form-control-alternative"
                            id="email"
                            value={createUser.email}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              O email é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Celular
                          </label>
                          <InputMask
                            type="text"
                            name="mobile"
                            placeholder="Celular"
                            className="form-control form-control-alternative"
                            id="mobile"
                            mask="(99) 99999-9999"
                            value={createUser.mobile}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O celular é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="birth_date"
                          >
                            Data de nascimento
                          </label>
                          <InputMask
                            type="text"
                            name="birth_date"
                            placeholder="Data de nascimento"
                            className="form-control form-control-alternative"
                            id="birth_date"
                            mask="99/99/9999"
                            value={createUser.birth_date}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              Data de nascimento é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Senha
                          </label>
                          <InputMask
                            type="password"
                            name="password"
                            placeholder="Senha"
                            className="form-control form-control-alternative"
                            id="password"
                            value={createUser.password}
                            onChange={handleOnChange}
                          />
                          {errors.name && (
                            <small className="text-danger">
                              O senha é obrigatório
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cpf"
                          >
                          CPF
                          </label>
                          <InputMask
                            type="text"
                            name="cpf"
                            placeholder="999.999.999-99"
                            className="form-control form-control-alternative"
                            id="cpf"
                            mask="999.999.999-99"
                            maskChar=""
                            value={createUser && createUser.cpf}
                            inputRef={register({ required: true })}
                            onChange={handleOnChange}
                          />
                          {errors.cpf && (
                            <small className="text-danger">
                              {Object.values(errors.cpf).reduce((t, n) => t + n, '').split('[')[0] === "required" ?
                                "CPF é obrigatório"
                                :
                                `CPF ${Object.values(errors.cpf).reduce((t, n) => t + n, '').split('[')[0]}`
                              }
                            </small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      createUser.intern_role && (

                        <Row>
                          {
                            user && (user.kind == 'master' || user.kind == 'operational') ?
                              <Col lg="3">
                                <Label for="intern_role">Defina a hierarquia</Label>
                                <Input type="select" value={createUser.intern_role} id="intern_role" name="intern_role" onChange={handleOnChange} >
                                  <option>Selecione o tipo</option>
                                  <option value="master">Master</option>
                                  <option value="operational">Operacional</option>
                                  {/* <option value="superintendent_commercial">Superintendente Comercial</option> */}
                                  <option value="superintendent">Superintendente</option>
                                  <option value="regional_manager">Gerente Regional</option>
                                  <option value="commercial_manager">Gerente Comercial</option>
                                  <option value="commercial_manager_internal">Gerente Comercial Interno</option>
                                  {/* <option value="supervisor_commmercial_internal">Supervisor Comercial Interno</option> */}
                                  <option value="visualizador">Visualizador</option>
                                  <option value="intern_consultant">Consultor interno</option>
                                </Input>
                              </Col>
                            :
                              <Col lg="3">
                                <Label for="exampleselect">Escolha a permissão</Label>
                                <Input type="select" id="exampleselect" name="role" onChange={handleOnChange} >
                                  <option>Selecione o tipo</option>
                                  <option value="admin">Admin</option>
                                  <option value="users">User</option>
                                </Input>
                              </Col>
                          }
                        </Row>

                      )
                    }
                    {
                      createUser.intern_role == "commercial_manager" ?
                        <Row>
                          <Col lg="3">
                            <Label for="regional_manager">Defina Gerente Regional</Label>
                            <Input
                              type="select"
                              value={createUser.regional_manager}
                              id="regional_manager"
                              name="regional_manager"
                              onChange={handleOnChange}
                            >
                              <option value="">Selecione uma opção</option>
                              {regionalManagers.map((regionalManager) => (
                                <option
                                  key={regionalManager.id}
                                  value={regionalManager.id}
                                >
                                  {regionalManager.name}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                      :
                        false
                    }
                    {
                      createUser.intern_role == "supervisor_commmercial_internal" ?
                        <Row>
                          <Col lg="3">
                            <Label for="regional_manager">Defina Gerente Comercial Interno</Label>
                            <Input
                              type="select"
                              id="regional_manager"
                              value={createUser.regional_manager}
                              name="regional_manager"
                              onChange={handleOnChange}
                            >
                              <option value="">Selecione uma opção</option>
                              {commercialManagers.map((commercialManager) => (
                                <option
                                  key={commercialManager.id}
                                  value={commercialManager.id}
                                >
                                  {commercialManager.name}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                      :
                        false
                    }
                    {
                      createUser.role == "users" ?
                        <>
                          {user.kind == "subestablishment" || user.kind == "corban" ?
                            <>
                              <Row>
                                <Col lg="6" className="ml-3">
                                  <br></br>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      name="visibility_store"
                                      defaultChecked={true}
                                      onChange={handleOnChangeCheck}/>{' '}
                                    Visualizar todos os lojas
                                  </Label>
                                </Col>
                              </Row>
                              <br/>
                              {
                                !createUser.visibility_store ?
                                  <Row>
                                    <Col lg="6">
                                      <Select
                                        name="visibility_store_select"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={stores}
                                        onChange={handleOnChangeSelectStore}
                                      />
                                    </Col>
                                    <br></br>
                                  </Row>
                                :
                                  false
                              }
                            </>
                          :
                            false
                          }
                          {user.kind == "corban" ?
                            <>
                              <Row>
                                <Col lg="6" className="ml-3">
                                  <br></br>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      name="visibility_sub"
                                      defaultChecked={true}
                                      onChange={handleOnChangeCheck}/>{' '}
                                    Visualizar todos as subestabelecimentos
                                  </Label>
                                </Col>
                              </Row>
                              <br/>
                              {
                                !createUser.visibility_sub ?
                                  <Row>
                                    <Col lg="6">
                                      <Select
                                        name="visibility_sub_select"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={subestablishment}
                                        onChange={handleOnChangeSelectSub}
                                      />
                                    </Col>
                                    <br/>
                                  </Row>
                                :
                                  false
                              }
                            </>
                          :
                            false
                          }
                        </>
                      :
                        false
                    }
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Enviar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateUser;
