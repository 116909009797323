import React from 'react';
const TableSimulationFgts = ({
  simulationDataFgts,
  tableName,
  selectedTable,
  productId,
  selectTableFgts,
  getCommissionSeller,
}) => {

  const selectedTableStyle = selectedTable.product_id === productId ?
    { border: '2px solid #EF7608aa' } :
    { border: '1px solid rgba(0, 0, 0, 0.1)' }

  const selectedTableTextStyle = selectedTable.product_id === productId ?
    { color: '#EF7608', fontSize: '1.2em', fontWeight: 'bold' } :
    { color: '#525f7f' }

  return (
    <tr style={selectedTableStyle}>
      <th
        className="d-flex align-items-center justify-content-center"
        style={{ borderBottom: 0 }}
        scope="row"
      >
        <input
          type="radio"
          name="radio1"
          style={{ border: 0 }}
          checked={selectedTable.product_id === productId}
          onChange={(e) => {
            console.log('SELECTED', simulationDataFgts);
            selectTableFgts(simulationDataFgts);
            getCommissionSeller(
              simulationDataFgts.response.liquidValue / 100,
              simulationDataFgts.product_id
            );
          }}
        />
      </th>
      <td style={selectedTableTextStyle}>{tableName}</td>
      <td style={selectedTableTextStyle}>
        {(simulationDataFgts.response.liquidValue / 100).toLocaleString(
          'pt-br',
          {
            style: 'currency',
            currency: 'BRL',
          }
        )}
      </td>
    </tr>
  );
};

export default TableSimulationFgts;
