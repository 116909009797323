import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Input,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { Link } from 'react-router-dom';

const CreateGatewaySetting = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const { user } = useContext(AuthContext);
  const [signer, setSigner] = useState({
    name: '',
    email: '',
    phone_number: '',
    documentation: '',
    birthdate: '',
  });

  const [gateway, setGateway] = useState('nexxera');

  async function onSubmit(data) {
    showLoader();

    data = {
      gateway_setting: {
        gateway,
      },
    };

    try {
      await api.post('gateway_settings', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Gateway criado com sucesso');

      props.history.push('/admin/gateways');
    } catch (error) {
      hideLoader();

      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error;
      }
      window.scrollTo(0, 0);
      toastError('HÃ¡ informaÃ§Ãµes incorretas, por favor revise-as.');
    }
  }

  function handleOnChange(e) {
    console.log(e);
    setGateway(e.target.value);
  }

  return (
    <>
      <CustomerHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="12">
                    <h3 className="mb-0">Selecionar Gateway</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="heading-small text-muted mb-4">Gateway</h6>
                  <div className="pl-lg-4">
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Row>
                              <Col>
                                <label
                                  className="form-control-label"
                                  htmlFor="name"
                                >
                                  Gateway
                                </label>
                                <Input
                                  type="select"
                                  name="select"
                                  id="exampleselect"
                                  value={gateway}
                                  onChange={(event) => handleOnChange(event)}
                                >
                                  <option>Selecione</option>
                                  <option value="nexxera">Nexxera</option>
                                  <option value="zoop">Zoop</option>
                                  <option value="ipag">Ipag</option>
                                  <option value="paynet">Paynet</option>
                                  <option value="ultrapayments">Ultrapayments</option>
                                  <option value="pagseguro">PagSeguro</option>
                                  <option value="delta_pag">Delta Pag</option>
                                  <option value="brasilcash">
                                    Brasil Cash
                                  </option>
                                </Input>
                                {errors.value && (
                                  <small className="text-danger">
                                    O gateway obrigatario
                                  </small>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <br></br>
                  </div>
                  <div className="text-right">
                    <Button type="submit" className="btn btn-success px-4">
                      <i className="fas fa-paper-plane" /> Selecionar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateGatewaySetting;
