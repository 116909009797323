import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
  Input,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { VisibilityByUserRoles } from '../../util/visibility';
import { toastError } from '../../util/toast';
import { LoadingContext } from '../../context/loading/LoadingContext';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import getToken from '../../util/setAuthToken';

const CustomerForm = ({
  customer,
  banks,
  titleButton,
  title,
  onSubmit,
  handleOnChange,
  dataAddress,
  register,
  hasFgts,
  handleSubmit,
  errors,
  hasPending,
  handleOnChangeBank,
  onlyBank,
  isSimplifiedPayment,
  hasClientFields,
  pixValueFieldDisabled,
  isReapresentation,
}) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [addressDisabled, setAddressDisabled] = useState(true);
  const [disabledRequest, setDisabledRequest] = useState(false);
  const [alreadyPageLog, setAlreadyPageLog] = useState('');
  const [pageType, setPageType] = useState('editar_cliente');
  const { user } = useContext(AuthContext);
  const query = new URLSearchParams(useLocation().search);

  async function handleBlurZipCode(event) {
    let cep = event.target.value;
    try {
      if (cep.length > 0) {
        showLoader();

        cep = cep.replace('-', '');

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!('erro' in data)) {
          dataAddress({
            street: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            state: data.uf,
          });
          setAddressDisabled(true);
        } else {
          dataAddress({
            street: '',
            district: '',
            city: '',
            state: '',
          });
          toastError('CEP inválido');
          setAddressDisabled(false);
        }

        hideLoader();
      }
    } catch (e) {
      hideLoader();
    }
  }

  useEffect(() => {
    async function getPageLog() {
      try {
        let { data } = await api.get(`page_log/${pageType}/${customer.id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        const res = data.filter(
          (item) => item.page_type === pageType && item.user_id !== user.id
        );

        if (res.length > 0) {
          setAlreadyPageLog(
            `Usuário ${res[0].name} está logado nesta página. Favor aguardar.`
          );
          // setDisabledRequest(true);
        }

        const result = data.filter((item) => item.page_type === pageType);
        if (result.length === 0) {
          await api.get(
            `customers/${customer.id}?name=${user.name}&page_type=${pageType}`,
            {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (customer.id) {
      getPageLog();
      localStorage.setItem('page_log', pageType);

      if (user) {
        // document.addEventListener('visibilitychange', function logData() {
        //   if (document.visibilityState === 'hidden') {
        //     navigator.sendBeacon(
        //       `https://nsaque.ultragate.com.br/api/v1/page_log_destroy_open/${pageType}?user_id=${user.id}`,
        //       'Encerrar'
        //     );
        //   }
        // });
      }
    }
  }, [user, customer]);

  return (
    <>
      <Container className={onlyBank ? '' : 'mt-2'} fluid={!onlyBank}>
        <Row>
          <Col sm="12">
            <Card className={onlyBank ? '' : 'bg-secondary'}>
              <CardHeader className="bg-white border-0">
                {!onlyBank && (
                  <Row className="align-items-center">
                    <Col sm="12">
                      <h3 className="mb-0">{title}</h3>
                    </Col>
                    {/* {alreadyPageLog !== '' && (
                      <h5
                        className="badge badge-warning"
                        style={{ marginLeft: 14, marginTop: 8 }}
                      >
                        {alreadyPageLog}
                      </h5>
                    )} */}
                  </Row>
                )}
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {!onlyBank && (
                    <>
                      <h6 className="heading-small text-muted mb-4">
                        Informações pessoais
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="name"
                              >
                                Nome Completo
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                id="name"
                                name="name"
                                // placeholder="Nome completo"
                                value={customer && customer.name}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                                maxLength={35}
                              />
                              {errors.name && (
                                <small className="text-danger">
                                  O nome é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control form-control-alternative"
                                id="email"
                                value={customer && customer.email}
                                // placeholder="example@example.com"
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  {Object.values(errors.email)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'Email é obrigatório'
                                    : `${
                                        Object.values(errors.email)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                              {/* <h5 className="badge badge-warning mt-0" style={{ fontSize: 10 }}>
                                Obrigatório cadastrar email pertencente ao cliente
                              </h5> */}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="mother_name"
                              >
                                Nome da Mãe
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                id="mother_name"
                                name="mother_name"
                                // placeholder="Nome da Mãe"
                                value={customer && customer.mother_name}
                                ref={register({
                                  required: 'É obrigatório',
                                  pattern: {
                                    value: /[a-zA-Z\u00C0-\u00FF ]+/i,
                                    message: 'Deve conter apenas letras',
                                  },
                                })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />

                              {errors.mother_name && (
                                <small className="text-danger">
                                  {errors.mother_name.message}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          {!hasFgts && (
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="father_name"
                                >
                                  Nome do Pai
                                </label>
                                <input
                                  type="text"
                                  name="father_name"
                                  className="form-control form-control-alternative"
                                  id="father_name"
                                  ref={register({
                                    pattern: {
                                      value:
                                        /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/,
                                      message: 'Deve conter apenas letras',
                                    },
                                  })}
                                  value={customer && customer.father_name}
                                  onChange={handleOnChange}
                                  disabled={disabledRequest}
                                />
                                {errors.father_name && (
                                  <small className="text-danger">
                                    {errors.father_name.message}
                                  </small>
                                )}
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="birth_date"
                              >
                                Data de nascimento
                              </label>
                              <input
                                type="date"
                                name="birth_date"
                                className="form-control form-control-alternative"
                                id="birth_date"
                                value={customer && customer.birth_date}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.birth_date && (
                                <small className="text-danger">
                                  {Object.values(errors.birth_date)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'Data de nascimento é obrigatório'
                                    : `${
                                        Object.values(errors.birth_date)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="cpf_cnpj"
                              >
                                CPF
                              </label>
                              <Input
                                type="text"
                                name="cpf_cnpj"
                                // placeholder="999.999.999-99"
                                className="form-control form-control-alternative"
                                id="cpf_cnpj"
                                value={customer && customer.cpf_cnpj}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.cpf_cnpj && (
                                <small className="text-danger">
                                  {Object.values(errors.cpf_cnpj)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'CPF é obrigatório'
                                    : `CPF ${
                                        Object.values(errors.cpf_cnpj)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="rg"
                              >
                                RG
                              </label>
                              <InputMask
                                type="text"
                                name="rg"
                                // placeholder="9.999.999"
                                className="form-control form-control-alternative"
                                id="rg"
                                value={customer && customer.rg}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.rg && (
                                <small className="text-danger">
                                  {Object.values(errors.rg)
                                    .reduce((t, n) => t + n, '')
                                    .split('[')[0] === 'required'
                                    ? 'RG é obrigatório'
                                    : `RG ${
                                        Object.values(errors.rg)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0]
                                      }`}
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="mobile"
                              >
                                Celular
                              </label>
                              <Input
                                type="text"
                                name="mobile"
                                className="form-control form-control-alternative"
                                id="mobile"
                                // placeholder="(99) 99999-9999"
                                value={customer && customer.mobile}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.mobile && (
                                <small className="text-danger">
                                  O celular é obrigatório
                                </small>
                              )}

                              {/* <h5 className="badge badge-warning mt-0" style={{ fontSize: 10 }}>
                                Obrigatório cadastrar celular pertencente ao cliente
                              </h5> */}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={'6 mb-4'}>
                            <label
                              className="form-control-label"
                              htmlFor="gender_customer"
                            >
                              Sexo
                            </label>
                            <Input
                              type="select"
                              id="gender_customer"
                              name="gender_customer"
                              value={customer && customer.gender_customer}
                              onChange={handleOnChange}
                              disabled={disabledRequest}
                            >
                              <option>Selecione o tipo</option>
                              <option value="MASCULINO">Masculino</option>
                              <option value="FEMININO">Feminino</option>
                            </Input>
                            {errors.gender_customer && (
                              <small className="text-danger">
                                {Object.values(errors.gender_customer)
                                  .reduce((t, n) => t + n, '')
                                  .split('[')[0] === 'required'
                                  ? 'Selecione o sexo'
                                  : `${
                                      Object.values(errors.gender_customer)
                                        .reduce((t, n) => t + n, '')
                                        .split('[')[0]
                                    }`}
                              </small>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Endereço do Cliente
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="zip_code"
                              >
                                CEP
                              </label>
                              <InputMask
                                name="zip_code"
                                className="form-control form-control-alternative"
                                id="zip_code"
                                // placeholder="CEP"
                                type="text"
                                mask="99999-999"
                                maskChar=""
                                value={customer && customer.zip_code}
                                inputRef={register({ required: true })}
                                onChange={handleOnChange}
                                onBlur={handleBlurZipCode}
                                disabled={disabledRequest}
                              />
                              {errors.zip_code && (
                                <small className="text-danger">
                                  O cep é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="street"
                              >
                                Endereço
                              </label>
                              <input
                                name="street"
                                className="form-control form-control-alternative"
                                id="street"
                                // placeholder="Endereço"
                                type="text"
                                value={customer && customer.street}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.street && (
                                <small className="text-danger">
                                  O endereço é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="number"
                              >
                                Número
                              </label>
                              <input
                                name="number"
                                className="form-control form-control-alternative"
                                id="number"
                                type="number"
                                maxLength={5}
                                value={customer && customer.number}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.number && (
                                <small className="text-danger">
                                  O número é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="district"
                              >
                                Bairro
                              </label>
                              <input
                                name="district"
                                className="form-control form-control-alternative"
                                id="district"
                                // placeholder="Bairro"
                                type="text"
                                value={customer && customer.district}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                              {errors.district && (
                                <small className="text-danger">
                                  O bairro é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="city"
                              >
                                Cidade
                              </label>
                              <input
                                name="city"
                                className="form-control form-control-alternative"
                                id="city"
                                // placeholder="Cidade"
                                type="text"
                                value={customer && customer.city}
                                ref={register({ required: true })}
                                onChange={handleOnChange}
                                disabled={addressDisabled || disabledRequest}
                              />
                              {errors.city && (
                                <small className="text-danger">
                                  A cidade é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="state"
                              >
                                UF
                              </label>
                              <input
                                name="state"
                                className="form-control form-control-alternative"
                                id="state"
                                // placeholder="Estado"
                                type="text"
                                value={customer && customer.state}
                                ref={register({ required: true, maxLength: 2 })}
                                onChange={handleOnChange}
                                disabled={addressDisabled || disabledRequest}
                              />
                              {errors.state && (
                                <small className="text-danger">
                                  O estado é obrigatório
                                </small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="complement"
                              >
                                Complemento
                              </label>
                              <input
                                name="complement"
                                className="form-control form-control-alternative"
                                id="complement"
                                // placeholder="Complemento"
                                type="text"
                                value={customer && customer.complement}
                                ref={register({ required: false })}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  {/* <VisibilityByUserRoles roles={['master', 'operational']}> */}
                    {!query.get('isCdc') && (
                      <>
                        <div style={{ display: 'flex' }}>
                          <h6 className="heading-small text-muted mb-4">
                            Dados de pagamento
                          </h6>
                        </div>
                        <Col className="d-flex align-items-center text-danger">
                          <h5
                            className="badge badge-warning"
                            style={{ fontSize: 18 }}
                          >
                            A conta informada deve estar em nome do cliente e
                            apenas pessoa física
                          </h5>
                        </Col>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg={onlyBank ? '6 mb-4' : '3 mb-4'}>
                              <Input
                                type="select"
                                id="kind_account"
                                name="kind_account"
                                value={customer && customer.kind_account}
                                onChange={handleOnChange}
                                disabled={disabledRequest}
                              >
                                <option value="">Selecione o tipo</option>
                                {<option value="ted">Dados Bancários</option>}
                                {<option value="pix">PIX</option>}
                              </Input>
                            </Col>
                          </Row>
                          {customer.kind_account === 'pix' ? (
                            <>
                              <Row>
                                <Col lg={onlyBank ? '6 mb-4' : '2 mb-4'}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="kind_pix"
                                  >
                                    Tipo Pix
                                  </label>
                                  <Input
                                    type="select"
                                    id="kind_pix"
                                    name="kind_pix"
                                    value={customer && customer.kind_pix}
                                    onChange={handleOnChange}
                                    disabled={disabledRequest}
                                  >
                                    {!hasFgts && (
                                      <option value="random">
                                        Selecione o tipo
                                      </option>
                                    )}
                                    <option value="cpf_cnpj">CPF</option>

                                    {(isSimplifiedPayment ||
                                      hasFgts ||
                                      !query.get('customerServiceId')) && (
                                      <>
                                        <option value="email">E-mail</option>
                                        <option value="phone">Telefone</option>
                                      </>
                                    )}
                                  </Input>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="pix"
                                    >
                                      Pix
                                    </label>
                                    <InputMask
                                      type={
                                        customer &&
                                        (customer.kind_pix == 'cpf_cnpj' ||
                                          customer.kind_pix == 'phone') &&
                                        !hasFgts
                                          ? 'number'
                                          : 'text'
                                      }
                                      name="pix"
                                      className="form-control form-control-alternative"
                                      id="pix"
                                      placeholder="Pix"
                                      value={customer && customer.pix}
                                      // inputRef={register({ required: true })}
                                      onChange={handleOnChange}
                                      disabled={
                                        customer &&
                                        customer.kind_pix === 'cpf_cnpj'
                                      }
                                    />
                                    {errors.pix && (
                                      <small className="text-danger">
                                        Pix é obrigatório
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>

                              {hasFgts && (
                                <Row>
                                  {/* <Col lg={onlyBank ? '8' : '6'}>
                                        <label
                                          className="form-control-label"
                                          htmlFor="agency_number"
                                        >
                                          Banco
                                        </label>
                                      <FormGroup>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="color"
                                          value={{
                                            value: customer.bank_name,
                                            label: customer.bank_name,
                                          }}
                                          options={banks}
                                          onChange={handleOnChangeBank}
                                          disabled={disabledRequest}
                                        />
                                      </FormGroup>
                                    </Col> */}
                                </Row>
                              )}
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col lg={onlyBank ? '8' : '6'}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="agency_number"
                                  >
                                    Banco
                                  </label>
                                  <FormGroup>
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      name="color"
                                      value={{
                                        value: customer.bank_name,
                                        label: customer.bank_name,
                                      }}
                                      options={banks}
                                      onChange={handleOnChangeBank}
                                      disabled={disabledRequest}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={onlyBank ? '8' : '6'}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="agency_number"
                                    >
                                      Agência
                                    </label>
                                    <InputMask
                                      type="text"
                                      name="agency_account"
                                      className="form-control form-control-alternative"
                                      id="agency_account"
                                      value={
                                        customer && customer.agency_account
                                      }
                                      inputRef={register({
                                        required:
                                          customer.kind_account == 'ted'
                                            ? true
                                            : false,
                                      })}
                                      onChange={handleOnChange}
                                      disabled={disabledRequest}
                                    />
                                    {errors.agency_account && (
                                      <small className="text-danger">
                                        {Object.values(errors.agency_account)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0] === 'required'
                                          ? 'Agência é obrigatório'
                                          : `${
                                              Object.values(
                                                errors.agency_account
                                              )
                                                .reduce((t, n) => t + n, '')
                                                .split('[')[0]
                                            }`}
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="2"></Col>
                                <Col lg={onlyBank ? '8' : '6'}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="number_account"
                                    >
                                      Conta
                                    </label>
                                    <InputMask
                                      type="text"
                                      name="number_account"
                                      className="form-control form-control-alternative"
                                      id="number_account"
                                      // placeholder="9999999"
                                      // mask="9999999"
                                      // maskChar=""
                                      value={
                                        customer && customer.number_account
                                      }
                                      inputRef={register({
                                        required:
                                          customer.kind_account == 'ted'
                                            ? true
                                            : false,
                                      })}
                                      onChange={handleOnChange}
                                      disabled={disabledRequest}
                                      minLength={5}
                                    />
                                    {errors.number_account && (
                                      <small className="text-danger">
                                        {Object.values(errors.number_account)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0] === 'required'
                                          ? 'Conta é obrigatória'
                                          : `${
                                              Object.values(
                                                errors.number_account
                                              )
                                                .reduce((t, n) => t + n, '')
                                                .split('[')[0]
                                            }`}
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="agency_number"
                                    >
                                      Dígito
                                    </label>
                                    <InputMask
                                      type="text"
                                      name="account_digit"
                                      className="form-control form-control-alternative"
                                      id="agency_account"
                                      // placeholder="9"
                                      mask="*"
                                      maskChar=""
                                      value={customer && customer.account_digit}
                                      inputRef={register({
                                        required:
                                          customer.kind_account == 'ted'
                                            ? true
                                            : false,
                                      })}
                                      onChange={handleOnChange}
                                      disabled={disabledRequest}
                                    />
                                    {errors.account_digit && (
                                      <small className="text-danger">
                                        {Object.values(errors.account_digit)
                                          .reduce((t, n) => t + n, '')
                                          .split('[')[0] === 'required'
                                          ? 'Digito é obrigatório'
                                          : `${
                                              Object.values(
                                                errors.account_digit
                                              )
                                                .reduce((t, n) => t + n, '')
                                                .split('[')[0]
                                            }`}
                                      </small>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={onlyBank ? '6 mb-4' : '2 mb-4'}>
                                  <label
                                    className="form-control-label"
                                    htmlFor="account_kind"
                                  >
                                    Tipo de conta
                                  </label>
                                  <Input
                                    type="select"
                                    id="account_kind"
                                    name="account_kind"
                                    value={customer && customer.account_kind}
                                    onChange={handleOnChange}
                                    disabled={disabledRequest}
                                  >
                                    <option>Selecione o tipo</option>
                                    <option value="0">Conta Corrente</option>
                                    <option value="1">Poupança</option>
                                  </Input>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  {/* </VisibilityByUserRoles> */}
                  {
                    <div className="text-right">
                      <Button
                        type="submit"
                        className="btn btn-success px-4"
                        disabled={disabledRequest}
                      >
                        <i className="fas fa-paper-plane" /> {titleButton}
                      </Button>
                    </div>
                  }
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerForm;
