import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../context/loading/LoadingContext';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import CorbanForm from '../../components/CorbanForm';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError, toastSuccess } from '../../util/toast';
import { AuthContext } from '../../context/AuthContext';
import { useForm } from 'react-hook-form';

const CreateCorban = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { register, handleSubmit, errors, setError } = useForm();
  const [corban, setCorban] = useState({
    social_reason: '',
    name: '',
    cpf_cnpj: '',
    has_secure: true,
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    has_proposed_permission: '',
    fgts_has_commission: '',
    commission_type: '',
    value: '',
    trigger: '',
    agency_digit: '',
    account_digit: '',
    calculate_type: 0,
    type_corban: '',
    referrer_id: '',
    password: 'novosaque123',
    simplified_cut_value: '',
    kind_integrator: 'uy3',
    external_campaign_enabled: 'no',
  });

  const [contactFields, setContactFields] = useState([
    { mobile: '', email: '', type_contact: '' },
  ]);

  const [basicInterests, setBasicInterest] = useState();
  const [fgtsBasicInterests, setFgtsBasicInterest] = useState();

  const [commissions, setCommission] = useState([]);
  const [fgtsCommissions, setFgtsCommission] = useState([]);
  const [cdcCommissions, setCdcCommission] = useState([
    {
      id: 1,
      tac: 12,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      is_cdc: true,
      kind: 'base',
    },
    {
      id: 2,
      tac: 18,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      is_cdc: true,
      kind: 'base',
    },
    {
      id: 3,
      tac: 30,
      name: 'Tabela Gold CDC',
      commission_type: 0,
      value: 12,
      rate: 1.79,
      value_greater_thousand: 12,
      enabled: true,
      is_cdc: true,
      kind: 'base',
    },
    {
      id: 4,
      tac: 30,
      name: 'Tabela Light CDC',
      commission_type: 0,
      value: 6,
      rate: 1.55,
      value_greater_thousand: 6,
      enabled: true,
      is_cdc: true,
      kind: 'base',
    },
  ]);

  const [banks, setBanks] = useState([]);

  const [referrers, setReferrers] = useState([]);

  const [installmentFields, setIntallmentFields] = useState([]);

  function handleOnChangeInstallmentFields(index, indexField, event) {
    const values = [...installmentFields];
    if (event.target.name == 'installment') {
      values[index].installments[indexField]['installment'] =
        event.target.value;
    } else if (event.target.name == 'value') {
      values[index].installments[indexField]['value'] = event.target.value;
    }

    setIntallmentFields(values);
  }

  function handleClickInstallmentFields(index) {
    const values = [...installmentFields];
    values[index]['installments'] = [
      ...values[index].installments,
      { installment: '', value: '' },
    ];

    setIntallmentFields(values);
  }

  async function getTableSettings() {
    showLoader();

    try {
      const response = await api.get(`table_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      console.log(response.data);
      setFgtsCommission(response.data);
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  }

  function removeInstallmentFields(index, indexField) {
    const values = [...installmentFields];
    const installments = [...values[index].installments];

    console.log(installments[indexField]);

    installments[indexField]['_destroy'] = true;
    // installments.splice(indexField, 1);
    console.log(installments);

    values[index]['installments'] = installments;
    // console.log(values)
    setIntallmentFields(values);
  }

  useEffect(() => {
    async function getBasicInterest() {
      showLoader();

      try {
        const response = await api.get(`basic_interests`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        setBasicInterest(response.data);
        let arrayCommissions = [];
        response.data.forEach((item) => {
          arrayCommissions = [
            ...arrayCommissions,
            {
              basic_interest: item.value,
              basic_interest_id: item.id,
              commission_type: '',
              has_secure: true,
              value: '',
              trigger: '',
            },
          ];
        });
        setCommission(arrayCommissions);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
    async function getFgtsBasicInterest() {
      showLoader();

      try {
        const response = await api.get(
          `fgts_basic_interests?q[active_eq]=true`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        );

        getTableSettings();

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
    async function getBanks() {
      showLoader();

      try {
        const response = await api.get(`banks?q[active_eq]=true&q[has_partners_eq]=true`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: `${value.code} - ${value.name}`,
            label: `${value.code} - ${value.name}`,
          };
        });

        console.log(values);
        setBanks(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os bancos.');
      }
    }
    async function getReferrers() {
      showLoader();

      try {
        const response = await api.get(`referrers`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });

        const values = response.data.map((value, index) => {
          return {
            value: value.id,
            label: value.name,
          };
        });

        console.log(values);
        setReferrers(values);

        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os indicador.');
      }
    }

    getBasicInterest();
    getFgtsBasicInterest();
    getBanks();
    getReferrers();
  }, []);

  async function onSubmit(data) {
    console.log(installmentFields);

    let commissionParams = [];
    installmentFields.map((item) => {
      item.installments.map((i) => {
        commissionParams = [
          ...commissionParams,
          {
            basic_interest_id: item.interest_id,
            basic_interest: item.interest,
            commission_type: 'percentage',
            value: i.value,
            installment: i.installment,
          },
        ];
      });
    });

    console.log(commissionParams);

    showLoader();

    if (!corban.kind_pix) {
      toastError('Selecione o tipo de chave pix');
      hideLoader();
      return;
    }

    if (corban.kind_pix != 'random' && !corban.pix) {
      toastError('Preencher campo pix');
      hideLoader();
      return;
    }

    parseCommissionType();

    console.log(fgtsCommissions);

    data = {
      corban: {
        current_qtd_uy3_req: corban.current_qtd_uy3_req,
        qtd_uy3_requests_monthly: corban.qtd_uy3_requests_monthly,
        calculate_type: parseInt(corban.calculate_type, 10),
        corban_type: parseInt(corban.type_corban, 10),
        has_proposed_permission:
          corban.has_proposed_permission === 'yes' ? true : false,
        referrer_id: corban.referrer_id,
        webhook_url: corban.webhook_url,
        kind: corban.kind_table,
        simplified_payment: corban.simplified_payment,
        external_campaign_enabled:
          data.external_campaign_enabled === true ? 'yes' : 'no',
        simplified_cut_value: corban.simplified_cut_value,
        entity_attributes: {
          cpf_cnpj: corban.cpf_cnpj,
          name: corban.name,
          commission_fgts: corban.commission_fgts,
          social_reason: corban.social_reason,
          product: corban.product,
          product_cdc: corban.product_cdc,
          product_fgts: corban.product_fgts,
          periodicity: corban.periodicity,
          periodicity_fgts: corban.periodicity_fgts,
          periodicity_cdc: corban.periodicity_cdc,
          has_uy3_create: corban.has_uy3_create === 'yes' ? true : false,
          has_fgts_create: true,
          has_cdc_create: corban.has_cdc_create === 'yes' ? true : false,
          has_credit_create: corban.has_credit_create === 'yes' ? true : false,
          has_secure: corban.has_secure === 'yes' ? true : false,
          has_commission: corban.has_commission === 'yes' ? true : false,
          has_cdc_commission: corban.has_cdc_commission === 'yes' ? true : false,
          fgts_has_commission: true,
          address_attributes: {
            zip_code: corban.zip_code,
            street: corban.street,
            number: corban.number,
            district: corban.district,
            city: corban.city,
            state: corban.state,
            complement: corban.complement,
          },
          bank_account_attributes: {
            number_bank: corban.bank_name.split('-')[0],
            name_bank: corban.bank_name.split('-')[1],
            agency_account: corban.agency_account,
            agency_digit: corban.agency_digit,
            number_account: corban.number_account,
            account_digit: corban.account_digit,
            kind: corban.account_kind,
            kind_pix: corban.kind_pix,
            pix: corban.pix,
          },
          contacts_attributes: [...contactFields],
          commission_bases_attributes: [
            ...commissionParams.map((item) => {
              return {
                ...item,
                value:
                  item.commission_type === '0'
                    ? item.value.replace(',', '.')
                    : item.value,
              };
            }),
          ],
          fgts_commissions_attributes: [
            ...cdcCommissions.map((item) => {
              return {
                ...item,
                id: null,
                is_cdc: true,
                value:
                  item.commission_type == '0'
                    ? item.value.toString().replace(',', '.')
                    : item.value,
              };
            }),
            ...fgtsCommissions
              .filter((item) => item.kind === corban.kind_table)
              .map((item) => {
                return {
                  ...item,
                  id: null,
                  value:
                    item.commission_type == '0'
                      ? item.value.toString().replace(',', '.')
                      : item.value,
                };
              }),
          ],
        },
        users_attributes: [
          {
            cpf: corban.user_cpf,
            name: corban.user_name,
            mobile: corban.user_mobile,
            email: corban.user_email,
            password: corban.password,
          },
        ],
      },
      user: {
        cpf: corban.user_cpf,
        name: corban.user_name,
        mobile: corban.user_mobile,
        email: corban.user_email,
        password: corban.password,
      },
    };

    console.log('DATA', data);

    try {
      await api.post('corbans', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Correspondente Bancário criado com sucesso');

      props.history.push('/admin/corbans');
    } catch (error) {
      hideLoader();
      console.log(error.response);
      let message = '';

      if (error.response.data.error) {
        const responseErrors = error.response.data.error;
        responseErrors.forEach((e) => {
          console.log(e.key);
          console.log(e.model);
          console.log(e.value);
          if (e.key == 'Documento' && e.model == 'users') {
            setError('user_cpf', e.value);
          } else if (e.key == 'Documento' && e.model == 'entity') {
            setError('cpf_cnpj', e.value);
          } else if (e.key == 'email' && e.model == 'users') {
            setError('user_email', e.value);
          }
        });
        //   message = error.response.data.error;
        //   message.forEach((e) => {
        //     toastError(e);
        //   });
        // } else if (error.response.data) {
        //   message = error.response.data.message;
        // } else {
        //   message =
        //     'Ops, tivemos um erro na criação do Correspondente bancário, por favor tente novamente!';
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  function parseCommissionType() {
    const values = [...commissions];
    values.map((value) => {
      value.commission_type = parseInt(value.commission_type, 10);
      if (value.value[0] == 'R') {
        value.value = value.value.replace('R$', '').replace(',', '');
      }
    });

    setCommission(values);

    const valuesFgts = [...fgtsCommissions];
    valuesFgts.map((value) => {
      value.commission_type = parseInt(value.commission_type, 10);
      if (value.value[0] == 'R') {
        value.value = value.value.replace('R$', '').replace(',', '');
      }
    });

    setFgtsCommission(valuesFgts);
  }

  function parseCurrency(value) {
    if (typeof value === 'string' || value instanceof String) {
      return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
    } else {
      return value;
    }
  }

  function parsePercentage(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace('%', '').replace(',', '.');
    } else {
      return value;
    }
  }

  function parseDigit(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, 'x');
    } else {
      return value;
    }
  }

  function parseAccount(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.replace(/[^\d]+/g, '');
    } else {
      return value;
    }
  }

  function handleOnChange(e) {
    const values = { ...corban };

    if (e.target.name == 'value_percentage') {
      setCorban({ ...corban, ['value']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'trigger') {
      setCorban({ ...corban, ['trigger']: parsePercentage(e.target.value) });
    } else if (e.target.name == 'commission_type' && values['value'] != '') {
      setCorban({ ...corban, [e.target.name]: e.target.value, value: '' });
    } else if (e.target.name == 'agency_digit') {
      setCorban({ ...corban, ['agency_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'account_digit') {
      setCorban({ ...corban, ['account_digit']: parseDigit(e.target.value) });
    } else if (e.target.name == 'number_account') {
      setCorban({
        ...corban,
        ['number_account']: parseAccount(e.target.value),
      });
    } else if (e.target.name == 'cpf_cnpj') {
      if (e.target.value.length < 19) {
        let cpf_cnpj = e.target.value;

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})(\d)/, '$1.$2');

        cpf_cnpj = cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');

        cpf_cnpj = cpf_cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');

        cpf_cnpj = cpf_cnpj.replace(/(\d{4})(\d)/, '$1-$2');
        setCorban({ ...corban, [e.target.name]: cpf_cnpj });
      }
    } else if (e.target.name == 'user_cpf') {
      if (e.target.value.length < 15) {
        let user_cpf = e.target.value;
        if (user_cpf.length >= 12) {
          user_cpf = user_cpf
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        } else {
          user_cpf = user_cpf.replace(/(\d{3})(\d)/, '$1.$2');
        }
        setCorban({ ...corban, [e.target.name]: user_cpf });
      }
    } else if (e.target.name == 'user_mobile') {
      if (e.target.value.length < 16) {
        let user_mobile = e.target.value;

        if (user_mobile.length > 10) {
          user_mobile = user_mobile
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2})(\d{5})(\d)/, '($1) $2-$3');
        } else {
          user_mobile = user_mobile.replace(/^(\d{2})(\d)/, '($1) $2');
        }
        setCorban({ ...corban, [e.target.name]: user_mobile });
      }
    } else {
      setCorban({ ...corban, [e.target.name]: e.target.value });
    }
  }

  function handleOnChangeValueFix(e, index) {
    const values = [...commissions];

    values[index]['value'] = parseCurrency(e);
    setCommission(values);
  }

  function handleOnChangeValueFixFgts(e, index) {
    const values = [...fgtsCommissions];

    values[index]['value'] = parseCurrency(e);
    setFgtsCommission(values);
  }

  function handleOnAgencyDigits(e) {
    setCorban({
      ...corban,
      agency_digit: parseDigit(e.target.agency_digit),
    });
  }

  function handleOnAccountDigits(e) {
    setCorban({
      ...corban,
      account_digit: parseDigit(e.target.account_digit),
    });
  }

  function handleOnChangeContact(index, event) {
    const values = [...contactFields];
    if (event.target.name == 'email_contact') {
      values[index]['email'] = event.target.value;
    } else if (event.target.name == 'mobile_contact') {
      values[index]['mobile'] = event.target.value;
    }

    setContactFields(values);
  }

  function dataAddress({ street, district, city, state }) {
    setCorban({
      ...corban,
      street: street,
      district: district,
      city: city,
      state: state,
    });
  }

  function handleClickContact() {
    setContactFields([
      ...contactFields,
      { mobile: '', email: '', type_contact: '' },
    ]);
  }

  function handleClickCommission() {
    setCommission([
      ...commissions,
      { basic_interest: '', commission_type: '', value: '', trigger: '' },
    ]);
  }

  function handleClickCommissionRemove(index) {
    const values = [...commissions];
    values.splice(index, 1);
    setCommission(values);
  }

  function handleClickCommissionRemoveFgts(index) {
    const values = [...fgtsCommissions];
    values.splice(index, 1);
    setFgtsCommission(values);
  }

  function handleOnChangeCommission(event, index) {
    const values = [...commissions];

    if (
      event.target.name == 'value_percentage' ||
      event.target.name == 'trigger'
    ) {
      if (event.target.name != 'trigger') {
        values[index]['value'] = parsePercentage(event.target.value);
      } else {
        values[index]['trigger'] = parsePercentage(event.target.value);
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }

    values[index][event.target.name] = event.target.value;
    setCommission(values);
  }

  function handleOnChangeCommissionFgts(event, id, index) {
    const values = [...fgtsCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }

    values[index][event.target.name] = event.target.value;
    setFgtsCommission(values);
  }

  function handleOnChangeCommissionCdc(event, id, index) {
    const values = [...cdcCommissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }

    values[index][event.target.name] = event.target.value;
    setCdcCommission(values);
  }

  function handleClickContactRemove(index) {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  }

  function handleOnChangeTypeContact(event, index) {
    const values = [...contactFields];
    if (event.target.value == 'email') {
      values[index].type_contact = event.target.value;
    } else if (event.target.value == 'mobile') {
      values[index].type_contact = event.target.value;
    }

    setContactFields(values);
  }

  function handleOnChangeBank(e) {
    setCorban({ ...corban, bank_name: e.value });
  }

  function handleOnChangeReferrer(e) {
    setCorban({ ...corban, referrer_id: e.value });
  }

  function insertPercentage(e, index) {
    const values = [...commissions];
    if (e.target.name == 'trigger') {
      if (e.target.value.charAt(2) != '%') {
        values[index]['trigger'] = `${e.target.value}%`;
      }
    } else {
      if (e.target.value.charAt(2) != '%') {
        values[index]['value'] = `${e.target.value}%`;
      }
    }

    setCommission(values);
  }

  function insertPercentageCorban(e) {
    setCorban({ ...corban, [e.target.name]: `${e.target.value}%` });
  }

  function insertMoney(e, index) {
    const values = [...commissions];
    if (e.target.value.charAt(0) != 'R') {
      values[index]['value'] = `R$ ${e.target.value}`;
    }
    setCommission(values);
  }

  return (
    <>
      <CustomerHeader />
      <CorbanForm
        corban={corban}
        contactFields={contactFields}
        basicInterests={basicInterests}
        commissions={commissions}
        fgtsCommissions={fgtsCommissions}
        cdcCommissions={cdcCommissions}
        fgtsBasicInterests={fgtsBasicInterests}
        banks={banks}
        title="Criar Correspondente Bancário"
        titleButton="Criar Correspondente Bancário"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeContact={handleOnChangeContact}
        handleClickContact={handleClickContact}
        handleClickContactRemove={handleClickContactRemove}
        handleOnChangeTypeContact={handleOnChangeTypeContact}
        handleClickCommission={handleClickCommission}
        handleClickCommissionRemove={handleClickCommissionRemove}
        handleClickCommissionRemoveFgts={handleClickCommissionRemoveFgts}
        handleOnChangeCommission={handleOnChangeCommission}
        handleOnChangeCommissionFgts={handleOnChangeCommissionFgts}
        handleOnChangeCommissionCdc={handleOnChangeCommissionCdc}
        handleOnChangeValueFix={handleOnChangeValueFix}
        handleOnChangeValueFixFgts={handleOnChangeValueFixFgts}
        handleOnChangeBank={handleOnChangeBank}
        dataAddress={dataAddress}
        insertPercentage={insertPercentage}
        insertPercentageCorban={insertPercentageCorban}
        insertMoney={insertMoney}
        controller={'new'}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        referrers={referrers}
        handleOnChangeReferrer={handleOnChangeReferrer}
        handleOnChangeInstallmentFields={handleOnChangeInstallmentFields}
        installmentFields={installmentFields}
        setIntallmentFields={setIntallmentFields}
        removeInstallmentFields={removeInstallmentFields}
        handleClickInstallmentFields={handleClickInstallmentFields}
      />
    </>
  );
};

export default CreateCorban;
