import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Row,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  CustomInput,
  Button,
  Badge,
  Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { toastError } from '../../util/toast';
import Pagination from 'react-responsive-pagination';
import { LoadingContext } from '../../context/loading/LoadingContext';
import UpdateBankForm from './components/update_bank_form';
import CreateBankForm from './components/create_bank_form';

const LIMIT = 200;

const Banks = (props) => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const params = new URLSearchParams(props.location.search);

  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [filterCode, setFilterCode] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterKind, setFilterKind] = useState('');
  const [filterHasFgts, setFilterHasFgts] = useState('');
  const [filterHasCreditCard, setFilterHasCreditCard] = useState('');
  const [filterHasPartners, setFilterHasPartners] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [banks, setBanks] = useState([]);
  const [bank, setBank] = useState([]);
  const [numberRegister, setNumberRegister] = useState('');
  const [pages, setPages] = useState([]);
  const [offset, setOffset] = useState(() => {
    if (params.get('page')) {
      return Number(params.get('page'));
    }

    return 1;
  });

  useEffect(() => {
    getBanks();
  }, [offset, searchFilter]);

  async function getBanks() {
    showLoader();

    try {
      const response = await api.get(
        `banks/get_all_banks?page=${offset}&per_page=${LIMIT}${searchFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      setBanks(response.data);
      setNumberRegister(response.headers.total_count);

      const totalPages = response.headers.total_pages;

      setPages(totalPages);

      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os correspondentes bancários.');
    }
  }

  async function handlerOnClickFilter() {
    showLoader();
    if (params.get('page')) {
      setOffset(1);
    }

    let filterPath = '';

    if (filterCode) {
      filterPath += `&q[code_eq]=${filterCode}`;
    }

    if (filterName) {
      filterPath += `&q[name_cont]=${filterName}`;
    }

    if (filterKind) {
      filterPath += `&q[kind_eq]=${filterKind}`;
    }

    if (filterHasFgts) {
      filterPath += `&q[has_fgts_eq]=${filterHasFgts}`;
    }

    if (filterHasCreditCard) {
      filterPath += `&q[has_credit_card_eq]=${filterHasCreditCard}`;
    }

    if (filterHasPartners) {
      filterPath += `&q[has_partners_eq]=${filterHasPartners}`;
    }

    hideLoader();
    setSearchFilter(filterPath);
    setIsOpenFilter(false);
  }

  async function toogleStatus(bank_id, newStatus) {
    try {
      const { data } = await api.put(
        `banks/${bank_id}`,
        { bank: { active: newStatus } },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      let updatedBank = data;

      setBanks((banks) => {
        return banks?.map((bank) => {
          if (bank.id == updatedBank?.id) return updatedBank;

          return bank;
        });
      });
    } catch (e) {
      console.error('Ops, houve um erro ao atualizar o banco');
    }
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        {/* Page content */}
        <Container className="mt--7 pt-5" fluid>
          {/* Dark table */}
          <Row>
            <div className="col">
              <div className="text-right">
                <Link
                  className="btn btn-success mb-3 mt-2"
                  onClick={() => {
                    setBank(bank);
                    setIsOpenCreate(true);
                  }}
                >
                  <i className="fas fa-plus" /> Criar novo banco
                </Link>
              </div>
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <Row>
                    <Col lg="7">
                      <h3 className="text-white">Bancos</h3>
                    </Col>
                    <Col lg="5" className="mt-3 mt-lg-0">
                      <>
                        <div className="d-flex justify-content-end h-100 align-items-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setIsOpenFilter(true)}
                          >
                            <i className="fas fa-filter" /> Filtrar
                          </button>
                        </div>
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="ml-4">
                  {numberRegister > 0 ? (
                    <div className="text-white">
                      Registros encontrados {LIMIT * (offset - 1) + 1} -{' '}
                      {numberRegister <= LIMIT
                        ? numberRegister
                        : LIMIT * offset}{' '}
                      de {numberRegister}
                    </div>
                  ) : (
                    <div className="text-white">Nenhum registro encontrado</div>
                  )}
                </Row>
                <Table
                  className="align-items-center table-dark table-flush"
                  responsive
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Código</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {banks && banks.map((bank) => (
                      <tr key={bank.id}>
                        <td>{bank.code}</td>
                        <td>{bank.name}</td>
                        <td>
                          {bank.has_fgts && (<Badge color="default">FGTS</Badge>)}
                          {bank.has_credit_card && (<Badge color="secondary" className='ml-2'>Cartão de Crédito</Badge>)}
                          {bank.has_partners && (<Badge color="primary" className='ml-2'>Parceiros</Badge>)}
                        </td>
                        <td>{bank.active ? 'Ativo' : 'Desativado'}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() =>
                                  toogleStatus(bank.id, !bank.active)
                                }
                              >
                                {bank.active ? (
                                  <span>
                                    <i className="fas fa-times" /> Desativar
                                    banco
                                  </span>
                                ) : (
                                  <span>
                                    <i className="fas fa-check" /> Ativar banco
                                  </span>
                                )}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setBank(bank);
                                  setIsOpenUpdate(true);
                                }}
                              >
                                <span>
                                  <i className="fas fa-edit" /> Editar
                                </span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4 bg-transparent">
                  <Row>
                    <Col
                      lg={{ size: 6, offset: 6 }}
                      className="d-flex justify-content-end"
                    >
                      <Pagination
                        current={offset}
                        total={Number(pages)}
                        onPageChange={setOffset}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={isOpenUpdate}
        toggle={() => setIsOpenUpdate(!isOpenUpdate)}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={() => setIsOpenUpdate(!isOpenUpdate)}>
          Editar Banco
        </ModalHeader>
        {bank && (
          <UpdateBankForm
            bank={bank}
            setIsOpen={setIsOpenUpdate}
            isOpen={isOpenUpdate}
            banks={banks}
            setBanks={setBanks}
          />
        )}
      </Modal>

      <Modal
        isOpen={isOpenCreate}
        toggle={() => setIsOpenCreate(!isOpenCreate)}
        className={props.className}
        size="xl"
        backdrop="static"
      >
        <ModalHeader toggle={() => setIsOpenCreate(!isOpenCreate)}>
          Criar Banco
        </ModalHeader>
        {bank && (
          <CreateBankForm
            setIsOpen={setIsOpenCreate}
            isOpen={isOpenCreate}
            banks={banks}
            setBanks={setBanks}
          />
        )}
      </Modal>
      <Modal
        isOpen={isOpenFilter}
        toggle={isOpenFilter}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={isOpenFilter}>Filtros</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="code">
                    Código
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    placeholder="Digite o Código do Banco"
                    value={filterCode}
                    onChange={(e) => setFilterCode(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Digite o Código do Banco"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                <label className="form-control-label" htmlFor="kind">
                  Tipo Cadastro
                </label>
                <Row className='ml-3'>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_fgts"
                        defaultChecked={false}
                        onChange={(e) => setFilterHasFgts(e.target.checked)} />
                      FGTS
                    </Label>
                  </Col>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_credit_card"
                        defaultChecked={false}
                        onChange={(e) => setFilterHasCreditCard(e.target.checked)} />
                      Cartão de Crédito
                    </Label>
                  </Col>
                  <Col lg="4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="has_partners"
                        defaultChecked={false}
                        onChange={(e) => setFilterHasPartners(e.target.checked)} />
                      Parceiros
                    </Label>
                  </Col>
                </Row>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setFilterCode('');
              setFilterName('');
              setFilterKind('');
              setSearchFilter('');
              setIsOpenFilter(false);
            }}
          >
            Limpar Filtro
          </Button>
          <Button color="danger" onClick={() => setIsOpenFilter(false)}>
            Fechar
          </Button>
          <Button color="success" onClick={handlerOnClickFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Banks;
