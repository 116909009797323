import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReferrerForm from '../../components/ReferrerForm';
import ReferrerHeader from '../../components/Headers/ReferrerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

const UpdateReferrer = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const query = new URLSearchParams(useLocation().search);

  const [referrer, setReferrer] = useState({
    name: '',
    birth_date: '',
    cpf_cnpj: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    mobile: '',
    email: '',
    has_commission: '',
    commission_type: '',
    value: '',
    commission_id: '',
    social_reason: '',
  });

  const [ banks, setBanks ] = useState([])

  async function getBanks() {
    showLoader()

    try {
      const response = await api.get(
        `banks?q[active_eq]=true&q[has_partners_eq]=true`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      const values = response.data.map((value,index) => {
        return {
          value: `${value.code} - ${value.name}`,
          label: `${value.code} - ${value.name}`
        }
      })

      setBanks(values);

      hideLoader();

    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os bancos.');
    }
  }

  useEffect(() => {
    async function getReferrer() {
      showLoader();
      try {
        const { data } = await api.get(`referrers/${id}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(data)
        setReferrer({
          ...referrer,
          entity_id: data.entity.id,
          id: data.id,
          mobile: data.mobile,
          rg: data.rg,
          email: data.email,
          social_reason: data.social_reason,
          birth_date: data.birth_date,
          name: data.entity.name,
          cpf_cnpj: data.entity.cpf_cnpj,
          mother_name: data.mother_name,
          father_name: data.father_name,
          address_id: data.address ? data.address.id : null,
          zip_code: data.address ? data.address.zip_code : null,
          street: data.address ? data.address.street : null,
          number: data.address ? data.address.number : null,
          district: data.address ? data.address.district : null,
          city: data.address ? data.address.city : null,
          state: data.address ? data.address.state : null,
          complement: data.address ? data.address.complement : null,
          bank_account_id: data.bank_account ? data.bank_account.id : null,
          bank_id: data.bank_account ? data.bank_account.id : null,
          bank_name: data.bank_account
            ? `${data.bank_account.number_bank} - ${data.bank_account.name_bank}`
            : null,
          agency_account: data.bank_account
            ? data.bank_account.agency_account
            : null,
          agency_digit: data.bank_account
            ? data.bank_account.agency_digit
            : null,
          account_digit: data.bank_account
            ? data.bank_account.account_digit
            : null,
          number_account: data.bank_account
            ? data.bank_account.number_account
            : null,
          account_kind: data.bank_account ? data.bank_account.kind : null,
          kind_pix: data.bank_account ? data.bank_account.kind_pix : null,
          pix: data.bank_account ? data.bank_account.pix : null,
          month: data.bank_account ? data.bank_account.month : null,
          commission_id:
            data.entity.has_commission &&
            data.entity.commissions[0] &&
            data.entity.commissions[0].id
              ? data.entity.commissions[0].id
              : '',
          has_commission: data.entity.has_commission ? 'yes' : 'no',
          commission_type:
            data.entity.has_commission && data.entity.commissions[0]
              ? data.entity.commissions[0].commission_type == 'percentage'
                ? '0'
                : '1'
              : '',
          value:
            data.entity.has_commission && data.entity.commissions[0]
              ? data.entity.commissions[0].commission_type == 'percentage'
                ? `${data.entity.commissions[0].value}%`
                : `R$ ${data.entity.commissions[0].value}`
              : '',
        });
        hideLoader();
      } catch (error) {
        hideLoader();
      }
    }
    getReferrer();
    getBanks()
  }, []);

  const isValidEmail = email => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
  const isValidCPF = cpf => /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(
    cpf
  );
  const isValidCPFNumber = cpf => /^\d{11}$/.test(
    cpf
  );
  const isValidPhoneNumber = phone => /^\d{11}$/.test(
    phone
  );
  const isValidPhone = phone => /^\d{10}$/.test(
    phone
  );

  async function onSubmit(data) {
    showLoader();
    console.log(referrer)

    if(!referrer.kind_pix){
      toastError("Selecione o tipo de chave pix")
      hideLoader();
      return
    }

    if(referrer.kind_pix != 'random' && !referrer.pix){
      toastError("Preencher campo pix")
      hideLoader();
      return
    }


    if(referrer.kind_pix == 'email'){
      if(!isValidEmail(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }

    if(referrer.kind_pix == 'cpf_cnpj'){
      if(!isValidCPF(referrer.pix) && !isValidCPFNumber(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }

    if(referrer.kind_pix == 'phone'){
      if(!isValidPhoneNumber(referrer.pix) && !isValidPhone(referrer.pix)){
        toastError("Campo Pix: Formato invalido")
        hideLoader();
        return
      }
    }


    let address_attributes = {
      zip_code: referrer.zip_code,
      street: referrer.street,
      number: referrer.number,
      district: referrer.district,
      city: referrer.city,
      state: referrer.state,
      complement: referrer.complement,
    }

    let bank_account_attributes = {
      number_bank: referrer.bank_name.split('-')[0],
      name_bank: referrer.bank_name.split('-')[1],
      agency_account: referrer.agency_account,
      agency_digit: referrer.agency_digit,
      number_account: referrer.number_account,
      account_digit: referrer.account_digit,
      kind: referrer.account_kind,
      kind_pix: referrer.kind_pix,
      pix: referrer.pix,
      month: referrer.month ? referrer.month : "",
    }

    if(referrer.address_id){
      address_attributes = {...address_attributes, id: referrer.address_id}
    }

    if(referrer.bank_account_id){
      bank_account_attributes = {...bank_account_attributes, id: referrer.bank_account_id}
    }

    data = {
      referrer: {
        birth_date: referrer.birth_date,
        email: referrer.email,
        mobile: referrer.mobile,
        rg: referrer.rg,
        mother_name: referrer.mother_name,
        father_name: referrer.father_name,
        social_reason: referrer.social_reason,
        entity_attributes: {
          id: referrer.entity_id,
          name: referrer.name,
          cpf_cnpj: referrer.cpf_cnpj,
          has_commission: referrer.has_commission == 'yes' ? true : false,
          address_attributes,
          bank_account_attributes,
          commission_bases_attributes: [
            {
              id: referrer.commission_id,
              active: referrer.active,
              commission_type: parseInt(referrer.commission_type, 10),
              value: referrer.value
                ? referrer.value.toString().replace('R$', '').replace(',', '.')
                : referrer.value,
            },
          ],
        },
      },
    };

    try {
      await api.put(`referrers/${id}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Cliente atualizado com sucesso');

      if(query.get("referrerServiceId")) {
        swal({
          title: 'Atendimento',
          text:
            'Deseja realizar um novo contrato?',
          icon: 'success',
          buttons: ['Não', 'Sim'],
          dangerMode: true,
        }).then(async (next) => {
          if (next) {
            props.history.push(`/admin/contracts/create?referrerServiceId=${query.get("referrerServiceId")}`);
          }else {
            props.history.push('/admin/referrers');
          }
        });
      }else{
        props.history.push('/admin/referrers');
      }

    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do indicador, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          message = error.response.data.error;
          console.log(message)
          message.forEach((e) => {
            toastError(e);
          });

          return;
        }
        if (error.response.data) {
          message = error.response.data.message;
        }

        if (typeof message === 'undefined') {
          message =
            'Ops, tivemos um erro na atualização do indicador, por favor tente novamente!';
        }
      }

      toastError(message);
    }
  }

  function handleOnChangeBank(e){
    setReferrer({ ...referrer, 'bank_name': e.value })
  }

  function parseDigit(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "x")
    } else {
      return value
    }
  }

  function parseAccount(value) {
    if(typeof value === 'string' || value instanceof String){
      return value.replace(/[^\d]+/g, "")
    } else {
      return value
    }
  }

  function handleOnChange(e) {
    if (e.target.name == "agency_digit") {
      setReferrer ({ ...referrer, ["agency_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "account_digit") {
        setReferrer ({ ...referrer, ["account_digit"] : parseDigit(e.target.value) });
      } else if (e.target.name == "number_account") {
        setReferrer ({ ...referrer, ["number_account"] : parseAccount(e.target.value) });
      } else if (e.target.name == "cpf_cnpj") {
        if ( e.target.value.length < 15 ) {
          let cpf_cnpj = e.target.value
          if(cpf_cnpj.length >= 12) {
            cpf_cnpj=cpf_cnpj.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/,"$1.$2.$3-$4")
          } else {
            cpf_cnpj=cpf_cnpj.replace(/(\d{3})(\d)/,"$1.$2")
          }
          setReferrer ({ ...referrer,  [e.target.name]: cpf_cnpj});
        }
      } else if (e.target.name == "mobile") {
          if ( e.target.value.length < 16 ) {
            let mobile = e.target.value

            if(mobile.length > 10) {
              mobile=mobile.replace(/[^0-9]/g, '').replace(/^(\d{2})(\d{5})(\d)/,"($1) $2-$3")
            } else {
              mobile=mobile.replace(/^(\d{2})(\d)/,"($1) $2")
            }
            setReferrer ({ ...referrer,  [e.target.name]: mobile});
          }
      } else if (e.target.name == "value_percentage") {
        console.log(e.target.value)
        setReferrer({ ...referrer, ["value"] : e.target.value });
      } else {
        setReferrer({ ...referrer, [e.target.name]: e.target.value });
      }
    }

  function dataAddress({ street, district, city, state }) {
    setReferrer({
      ...referrer,
      street: street,
      district: district,
      city: city,
      state: state
    });
  }

  function insertMoney(e) {
    if(e.target.value.charAt(0) != 'R') {
      setReferrer({ ...referrer, ["value"]: `R$ ${e.target.value}` });
    }
  }

  function parseCurrency(value) {
    if(typeof value === 'string' || value instanceof String){
      return Number(value.replace(".", "").replace(",", ".").replace("R$", ""))
    } else {
      return value
    }
  }

  function handleOnChangeValueFix(e){
    setReferrer({
      ...referrer,
      value: parseCurrency(e)
    })
  }

  return (
    <>
      <ReferrerHeader />
      <ReferrerForm
        banks={banks}
        referrer={referrer}
        title="Atualizar indicador"
        titleButton="Atualizar indicador"
        onSubmit={onSubmit}
        handleOnChange={handleOnChange}
        handleOnChangeBank={handleOnChangeBank}
        dataAddress={dataAddress}
        register={register}
        handleSubmit={handleSubmit}
        handleOnChangeValueFix={handleOnChangeValueFix}
        insertMoney={insertMoney}
        errors={errors}
      />
    </>
  );
};

export default UpdateReferrer;
