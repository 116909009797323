import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input';

import InputMask from 'react-input-mask';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda, formatCurrency } from '../../util/mask';
import { LoadingContext } from '../../context/loading/LoadingContext';
import api from '../../services/api';
import getToken from '../../util/setAuthToken';
import ContractForm from '../../components/ContractForm';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

import ReactToPrint from 'react-to-print';

import './style.css';

function parseCurrency(value) {
  if (typeof value === 'string' || value instanceof String) {
    return Number(value.replace('.', '').replace(',', '.').replace('R$', ''));
  } else {
    return value;
  }
}
class DetailContractForm extends React.Component {
  // const { customer, banks } = props;

  render() {
    return (
      <CardBody>
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Informações do Vendedor
          </h6>
          <div className="pl-lg-4">
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Nome do Vendedor
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="name"
                        name="name"
                        disabled
                        value={this.props.customer.seller_name}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        Loja
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="email"
                        disabled
                        value={this.props.customer.store_name}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Número do vendedor
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="name"
                        name="name"
                        disabled
                        value={this.props.customer.mobile_seller}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        Número da loja
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="email"
                        disabled
                        value={this.props.customer.mobile_store}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="birth_date"
                      >
                        Subestabelecimento
                      </label>
                      <input
                        type="text"
                        name="birth_date"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="birth_date"
                        disabled
                        value={this.props.customer.sub_name}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cpf_cnpj">
                        Corban
                      </label>
                      <InputMask
                        type="text"
                        name="cpf_cnpj"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="cpf_cnpj"
                        value={this.props.customer.corban_name}
                        disabled
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="mobile">
                        Gerente Comercial
                      </label>
                      <InputMask
                        type="text"
                        name="mobile"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="mobile"
                        disabled
                        value={this.props.customer.user_manager_name}
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
          </div>

          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">
            Informações pessoais
          </h6>
          <div className="pl-lg-4">
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Nome Completo
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="name"
                        name="name"
                        disabled
                        value={this.props.customer.name}
                        maxLength={35}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="email"
                        disabled
                        value={this.props.customer.email}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mother_name"
                      >
                        Nome da Mãe
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="mother_name"
                        disabled
                        name="mother_name"
                        placeholder="Nome da Mãe"
                        value={
                          this.props.customer && this.props.customer.mother_name
                        }
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="father_name"
                      >
                        Nome do Pai
                      </label>
                      <input
                        type="text"
                        name="father_name"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="father_name"
                        disabled
                        value={
                          this.props.customer && this.props.customer.father_name
                        }
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="birth_date"
                      >
                        Data de nascimento
                      </label>
                      <input
                        type="date"
                        name="birth_date"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="birth_date"
                        disabled
                        value={this.props.customer.birth_date}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="cpf_cnpj">
                        CPF
                      </label>
                      <InputMask
                        type="text"
                        name="cpf_cnpj"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="cpf_cnpj"
                        value={this.props.customer.cpf_cnpj}
                        mask="999.999.999-99"
                        disabled
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="rg">
                        RG
                      </label>
                      <InputMask
                        type="text"
                        name="rg"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="rg"
                        disabled
                        value={this.props.customer.rg}
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="mobile">
                        Celular
                      </label>
                      <InputMask
                        type="text"
                        name="mobile"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="mobile"
                        mask="(99) 99999-9999"
                        disabled
                        value={this.props.customer.mobile}
                        input
                      />
                    </FormGroup>
                    {this.props.customer.mobile && (
                      <a
                        href={`https://wa.me/55${this.props.customer.mobile.replace(
                          /\D+/g,
                          ''
                        )}`}
                        class="btn btn-success text-white"
                        target="_blank"
                      >
                        <i class="fab fa-lg fa-whatsapp" />
                      </a>
                    )}
                  </Col>
                </td>
              </table>
            </Row>
          </div>

          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">Endereço do Cliente</h6>
          <div className="pl-lg-4">
            <Row>
              <table className="table">
                <td>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="zipcode">
                        CEP
                      </label>
                      <InputMask
                        name="zipcode"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="zipcode"
                        type="text"
                        mask="99999-999"
                        disabled
                        value={this.props.customer.zipcode}
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col md="10">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="street">
                        Endereço
                      </label>
                      <input
                        name="street"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="street"
                        type="text"
                        disabled
                        value={this.props.customer.street}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col md="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="number">
                        Número
                      </label>
                      <input
                        name="number"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="number"
                        type="number"
                        disabled
                        value={this.props.customer.number}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="district">
                        Bairro
                      </label>
                      <input
                        name="district"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="district"
                        type="text"
                        disabled
                        value={this.props.customer.district}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="city">
                        Cidade
                      </label>
                      <input
                        name="city"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="city"
                        type="text"
                        disabled
                        value={this.props.customer.city}
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="state">
                        Estado
                      </label>
                      <input
                        name="state"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="state"
                        type="text"
                        disabled
                        value={this.props.customer.state}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="complement"
                      >
                        Complemento
                      </label>
                      <input
                        name="complement"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="complement"
                        type="text"
                        disabled
                        value={this.props.customer.complement}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
          </div>

          <h6 className="heading-small text-muted mb-4">Dados Bancários</h6>
          <div className="pl-lg-4">
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="color"
                        value={{
                          value: this.props.customer.bank_name,
                          label: this.props.customer.bank_name,
                        }}
                        options={this.props.banks}
                        isDisabled={true}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="agency_number"
                      >
                        Agência
                      </label>
                      <InputMask
                        type="text"
                        name="agency_account"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="agency_account"
                        mask="9999"
                        maskChar=""
                        disabled
                        value={
                          this.props.customer &&
                          this.props.customer.agency_account
                        }
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="2">
                    {/* <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="agency_number"
                      >
                        Dígito
                      </label>
                      <InputMask
                        type="text"
                        name="agency_digit"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="agency_account"
                        mask="*"
                        maskChar=""
                        disabled
                        value={this.props.customer && this.props.customer.agency_digit}


                      />
                    </FormGroup> */}
                  </Col>
                </td>
              </table>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="number_account"
                      >
                        Conta
                      </label>
                      <InputMask
                        type="text"
                        name="number_account"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="number_account"
                        // mask="9999999"
                        // maskChar=""
                        disabled
                        value={
                          this.props.customer &&
                          this.props.customer.number_account
                        }
                        input
                      />
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="2">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="agency_number"
                      >
                        Dígito
                      </label>
                      <InputMask
                        type="text"
                        name="account_digit"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="agency_account"
                        mask="*"
                        maskChar=""
                        disabled
                        value={
                          this.props.customer &&
                          this.props.customer.account_digit
                        }
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <label for="account_kind">Tipo de conta</label>
                    <InputMask
                      type="text"
                      name="account_digit"
                      className="form-control form-control-alternative form-control-alternative-custom"
                      id="agency_account"
                      disabled
                      value={
                        this.props.customer
                          ? this.props.customer.account_kind == 1
                            ? 'Poupança'
                            : this.props.customer.account_kind == 0
                            ? 'Conta Corrente'
                            : 'Não Informado'
                          : 'Não Informado'
                      }
                    />
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="kind_pix">
                        Tipo Pix
                      </label>
                      <Input
                        type="select"
                        id="kind_pix"
                        disabled
                        className="form-control form-control-alternative form-control-alternative-custom"
                        name="kind_pix"
                        value={
                          this.props.customer && this.props.customer.kind_pix
                        }
                      >
                        <option>Selecione o tipo</option>
                        <option value="cpf_cnpj">CPF/CNPJ</option>
                        <option value="email">E-mail</option>
                        <option value="phone">Telefone</option>
                        <option value="random">
                          {'Dados Bancários (conforme abaixo)'}
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="pix">
                        Pix
                      </label>
                      <InputMask
                        type="text"
                        name="pix"
                        className="form-control form-control-alternative form-control-alternative-custom"
                        id="pix"
                        disabled
                        value={this.props.customer && this.props.customer.pix}
                      />
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
            <Row>
              <table className="table">
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="month">
                        Vencimento da fatura do cartão que será utilizado
                      </label>
                      <Input
                        type="select"
                        id="month"
                        name="month"
                        disabled
                        value={this.props.customer && this.props.customer.month}
                      >
                        <option>Selecione</option>
                        {[
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20',
                          '21',
                          '22',
                          '23',
                          '24',
                          '25',
                          '26',
                          '27',
                          '28',
                          '29',
                          '30',
                          '31',
                        ].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </td>
                <td>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="kind_credit_card"
                      >
                        Tipo de cartão que será utilizado
                      </label>
                      <Input
                        type="select"
                        id="kind_credit_card"
                        name="kind_credit_card"
                        disabled
                        value={
                          this.props.customer &&
                          this.props.customer.kind_credit_card
                        }
                      >
                        <option>Vazio</option>
                        {[
                          'Cartão de crédito PORTO SEGURO',
                          'Cartão de crédito BANCO DE REDE (BB, CEF, Itaú, etc.)',
                          'Cartão de crédito BANCO DIGITAL (Nubank, Inter, C6, etc.)',
                          'Cartão de crédito LOJA (Magalu, Renner, Casas Bahia, etc.)',
                          'Cartão Consignado INSS (RMC)',
                          'Cartão Consignado SIAPE',
                          'Cartão Benefício GOV. e PREF. (ex.: CREDCESTA)',
                          'Cartão Benefício INSS',
                        ].map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </td>
              </table>
            </Row>
          </div>

          <>
            <hr className="my-4" />
            {/* Contract */}
            <h6 className="heading-small text-muted mb-4">
              Informações do Contrato
            </h6>
            <div className="pl-lg-4">
              <table className="table">
                <tr>
                  <td>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="contract_number"
                        >
                          Nº do Contrato
                        </label>

                        <input
                          name="contract_number"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="contract_number"
                          type="text"
                          disabled
                          maxLength="45"
                          value={this.props.customer.contract_number}
                        />
                      </FormGroup>
                    </Col>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Col lg="4">
                      <label
                        className="form-control-label"
                        htmlFor="contract_value"
                      >
                        {this.props.customer.kind == 'fgts'
                          ? 'Valor Liquido'
                          : 'Valor do Contrato'}
                      </label>

                      <InputGroup className="input-group-alternative">
                        <CurrencyInput
                          prefix="R$"
                          decimalSeparator=","
                          thousandSeparator="."
                          name="contract_value"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="contract_value"
                          type="text"
                          disabled
                          value={this.props.customer.kind == 'fgts' ? this.props.customer.liquid_value : this.props.customer.contract_value}
                          input
                        />
                      </InputGroup>
                    </Col>
                  </td>
                  {this.props.customer.kind === 'fgts' && (
                    <td>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="amount_charged"
                        >
                          GRADE
                        </label>
                        <input
                          name="installments"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="installments"
                          value={this.props.customer.tac_table_name}
                          disabled
                          type="text"
                        />
                      </Col>
                    </td>
                  )}
                  {/* <td>
                    <Col lg="4">
                      <label className="form-control-label" htmlFor="amount_charged">
                      {this.props.customer.kind == "fgts" ? "Valor Bruto" : "Valor Cobrado"}
                      </label>

                      <InputGroup className="input-group-alternative">
                        <CurrencyInput
                          prefix="R$"
                          decimalSeparator=","
                          thousandSeparator="."
                          name="amount_charged"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="amount_charged"
                          type="text"
                          disabled
                          value={this.props.customer.amount_charged}
                          input

                        />
                      </InputGroup>

                    </Col>
                  </td> */}
                  {this.props.customer.kind != 'fgts' && (
                    <td>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="amount_charged"
                        >
                          Valor da Parcela
                        </label>
                        <InputGroup className="input-group-alternative">
                          <CurrencyInput
                            prefix="R$"
                            decimalSeparator=","
                            thousandSeparator="."
                            name="amount_charged"
                            className="form-control form-control-alternative form-control-alternative-custom"
                            id="amount_charged"
                            type="text"
                            disabled
                            value={
                              parseCurrency(
                                this.props.customer.amount_charged
                              ) / parseInt(this.props.customer.installments)
                            }
                            input
                          />
                        </InputGroup>
                      </Col>
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="installments"
                        >
                          Qtde. Parcelas
                        </label>
                        <input
                          name="installments"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="installments"
                          value={this.props.customer.installments}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </td>
                  <td>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="installments"
                        >
                          Taxa de Juros
                        </label>
                        <input
                          name="installments"
                          className="form-control form-control-alternative form-control-alternative-custom"
                          id="installments"
                          value={`${this.props.customer.basic_interest}%`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </td>
                </tr>
              </table>
            </div>
          </>
        </Form>
      </CardBody>
    );
  }
}

export default DetailContractForm;
